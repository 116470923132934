import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import UserProfile from "./UserProfile";
import SendReportsComp from "./SendReportsComp";
import Patterns from "./Patterns";
import EditingMain from "./EditingMain";
import EditingPassword from "./EditingPassword";

const Account = () => {
    const token = localStorage.getItem("token");
    
    if (!token) {
        return <Navigate to="/authorization" />;
    }
    
    return (
        <Routes>
            <Route path="/" element={<UserProfile/>} />
            <Route path="/reports/send" element={<SendReportsComp />} />
            <Route path="/templates" element={<Patterns />} />
            <Route path="/editing" element={<EditingMain />} />
            <Route path="/editing/password" element={<EditingPassword />} />
        </Routes>
    );
};

export default Account;