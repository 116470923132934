import React, { useState } from "react";
import "../../../../styles/desktop/account/SendReports.css";
import contour from "../../../images/account/send reports/add_photo_contour.svg";
import inside_hover from "../../../images/account/send reports/add_photo_hover.svg";
import inside from "../../../images/account/send reports/addReport_inside.svg";

const AddReportBut = (props) => {
  const { sendData } = props;
  const [hover, setHover] = useState(false)
  const pic = () => {
    return hover ? inside_hover : inside;
  };

  return (
    <span className="addReportCont">
      {pic() && <img src={pic()} alt="Inside" className="photoInside" />}
      <img src={contour} alt="Contour" className="photoContour" />
      <button 
      onClick={sendData}
        id="upload-btn" 
        className={'photoButton'} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
      >
        Прикрепить отчет
      </button>
    </span>
  );
};

export default AddReportBut;