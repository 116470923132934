import React, { useState } from "react";
import classes from "../../../styles/mobile/account/EditingMain.module.css"
import classesP from "../../../styles/mobile/account/EditingPassword.module.css"
import contour from "../../images/account/editing parts/input_contour.svg"
import contour_wrong from "../../images/account/editing parts/confirmInput_contour_wrong.svg"
import sign_right from "../../images/form parts/signs/sign_right.svg"
import sign_wrong from "../../images/form parts/signs/sign_wrong.svg"

const ConfirmInput = (props) => {
    const {placeholder, caption, value, action, newPassword, confirmPassword} = props
    return (
    <span className={classes.editInputCont}>
        <img src={confirmPassword !== '' && confirmPassword === newPassword ? contour : (confirmPassword !== '' ? contour_wrong : contour)}
            className={classes.inputContour}/>
        <input 
          value={value}
          className={classes.inputStyle} 
          placeholder={placeholder} 
          maxLength={100} 
          type="password"
          style={{fontSize: "22px"}}
          onChange={(event) => {
            const newValue = event.target.value;
            action(newValue);
            console.log(confirmPassword)
          }}
        />
        <a className={classes.inputCaption}>{caption}</a>
        <img src={confirmPassword!=='' && confirmPassword===newPassword ? sign_right : sign_wrong}
            className={classesP.signStyle}
            style={confirmPassword==='' ? { display: "none" } : {}}/>
        {props.children}
    </span>
  );
};

export default ConfirmInput;