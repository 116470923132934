import React, { useRef } from 'react';

function ButtonMain1({block2Ref}) {
    

    const scrolltoBlock = () => {
        block2Ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    
    return (
        <div className={'gov'}>
            <button className='bb' onClick={scrolltoBlock}> Узнать подробнее</button>
        </div>
    );
};

export default ButtonMain1;