import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ListItem from './ListItem';
import SendReport from '../../../components/comps/account comps/templates/ReportButton';

const List = () => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const dataHandle = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Token " + token
        }
      };
      try {
        const response = await axios.get('/api/account/get_full_report_history/', config);
        const userData = response.data.data;
        const formattedData = userData.map((item, index) => ({
          number: index + 1,
          photo: item.images[0]?.report_img || 'No image',
          date: new Date(item.report_snippet.datetime_uploaded).toLocaleDateString(),
          status: getStatusText(item.report_snippet.status),
          comment: item.report_snippet.response_description || 'Нет комментариев',
          payment: item.reward !== 'null' ? `${item.reward}₽` : 'Нет оплаты',
        }));
        setItems(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error.request.response);
      }
    };

    dataHandle();
  }, []);

  const getStatusText = (status) => {
    switch (status) {
      case 0: return 'В обработке';
      case 1: return 'Отклонен';
      case 2: return 'Принято';
      default: return 'Неизвестный статус';
    }
  };

  const totalPages = Math.ceil(items.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);

  const [sortOrder, setSortOrder] = useState({
    number: 'asc',
    date: 'asc',
    status: 'asc',
  });

  const sortByNumber = () => {
    const sortedItems = [...items].sort((a, b) => sortOrder.number === 'asc' ? a.number - b.number : b.number - a.number);
    setItems(sortedItems);
    setSortOrder({ ...sortOrder, number: sortOrder.number === 'asc' ? 'desc' : 'asc' });
  };

  const sortByDate = () => {
    const sortedItems = [...items].sort((a, b) => sortOrder.date === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date));
    setItems(sortedItems);
    setSortOrder({ ...sortOrder, date: sortOrder.date === 'asc' ? 'desc' : 'asc' });
  };

  const sortByStatus = () => {
    const sortedItems = [...items].sort((a, b) => sortOrder.status === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status));
    setItems(sortedItems);
    setSortOrder({ ...sortOrder, status: sortOrder.status === 'asc' ? 'desc' : 'asc' });
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='reports-main-container'>
      <div className="list-header">
        <div onClick={sortByNumber}>№</div>
        <div>Фото</div>
        <div onClick={sortByDate}>Дата</div>
        <div onClick={sortByStatus}>Статус</div>
        <div>Комментарий</div>
        <div>Оплата</div>
      </div>
      {currentItems.map(item => (
        <ListItem
          key={item.number}
          number={item.number}
          photo={item.photo}
          date={item.date}
          status={item.status}
          comment={item.comment}
          payment={item.payment}
        />
      ))}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <SendReport/>
    </div>
  );
};

export default List;
