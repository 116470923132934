import React, { useState } from "react";
import RegistrationForm from "../services/authorization forms/registration/RegistrationForm";
import EnterForm from "../services/authorization forms/enter/EnterForm";
import "../../styles/desktop/form styles/Form.css";
import { Navigate } from "react-router-dom";

function Athorization() {
  const [animState, setAnimState] = useState(1);
  const token = localStorage.getItem("token");
    
  if (token) {
      return <Navigate to="/account/templates" />;
  }

  return (
    <div className="horizontalContainer">
      <RegistrationForm animState={animState} setAnimState={setAnimState} />
      <EnterForm animState={animState} setAnimState={setAnimState} />
    </div>
  );
}

export default Athorization;