import React, { useEffect, useState } from "react";
import classes from '../../../../styles/desktop/account/Patterns.module.css';
import arrow_left from "../../../../components/images/account/patterns parts/arrow_left.svg"
import arrow_left_inside from "../../../../components/images/account/patterns parts/arrow_inside_left.svg"
import arrow_hover from "../../../../components/images/account/patterns parts/arrow_hover.svg"

const Arrow = (props) => {
    const {type, setPatternNumb, patternNumb, patterns} = props
    const [hover, setHover] = useState(false);
    const [patternsCount, setPatternsCount] = useState(0);

    useEffect(() => {
        setPatternsCount(patterns.length-1);
    }, [patterns]);
    
    const pic = () => {
        return hover ? arrow_hover : arrow_left_inside;
    };

    const moveLeft =()=> {
        if (patternNumb === 0) {setPatternNumb(patternsCount);} 
        else {setPatternNumb(patternNumb - 1);}
    }
    
    const moveRight =()=> {
        if (patternNumb === patternsCount) {setPatternNumb(0);} 
        else {setPatternNumb(patternNumb + 1);}
    }
    
    return type === "left" ? (
        <span className={classes.arrowContLeft}>
            <img src={pic()} alt="Arrow Inside" className={classes.arrowInside}/>
            <img src={arrow_left} alt="Arrow Inside" className={classes.arrow}/>
            <button onClick={moveLeft} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.arrowBut}/>
        </span>
    ) : (
        <span className={classes.arrowContRight}>
          <img src={pic()} alt="Arrow Inside" className={classes.arrowInside} style={{ transform: "scaleX(-1)" }} />
          <img src={arrow_left} alt="Arrow Inside" className={classes.arrow} style={{ transform: "scaleX(-1)" }}/>
          <button onClick={moveRight} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.arrowBut}/>
        </span>
    );
  };
  
  export default Arrow;