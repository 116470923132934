import React, { useState } from "react";
import contour from "../../../components/images/account/patterns parts/teamBut_contour.svg";

import inside from "../../../components/images/account/patterns parts/teamBut_inside.svg";
import inside_hover from "../../../components/images/account/patterns parts/teamBut_hover.svg";
import { useNavigate } from 'react-router-dom';

import '../../../styles/mobile/MainPage/Team_button_mobile.css'

const Team_button_mobile = (

) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const pic = () => {
      return hover ? inside_hover : inside;
    };
    const handleClick = () => {
        navigate('/account/reports/send');
        window.scrollTo(0,0);
    };
    
    return (
        <div className='team-button-container-mobile'>
            <div className="reportButtonCont-mobile">
            <img src={pic()} alt="Inside" className={'reportInside'} />
            <img src={contour} alt="Contour" className={'reportContour'} />
            <button
                onClick={handleClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={'reportButton-mobile'}
            >
                Присоединиться к команде
            </button>
            </div>

            
        </div>
    );
};

export default Team_button_mobile;