import React, { useState, useEffect } from "react";
import classes from '../../../styles/mobile/account/Patterns.module.css'
import axios from "axios";

const SocialMedia = (props) => {
    const {setNetwork} = props
    const [data, setData] = useState({})
    const dataHandle = () => {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: "Token "+token
          }
        };
        axios.get('/api/account/get_social_network_list/', config)
          .then(response => {
            // Обработка успешного ответа
            setData(response.data.data);
            console.log(response.data.data);
          })
          .catch(error => {
            // Обработка ошибки
            console.error(error.request.response);
          });
    };

    useEffect(() => {
        dataHandle()
    }, []);

    return (
        <div className={classes.mediaContainer}>
            Выберите социальную сеть:
            <span className={classes.logosCont}>
              {data && data.length > 0 && data.map((item, index) => (
                  <img key={index} src={item.icon} className={classes.logo} alt={item.code_name} onClick={()=>setNetwork(item.pk)}/>
              ))}
            </span>
        </div>
    );
};

export default SocialMedia;