import React, { useState } from 'react';
import sign_up from "../../images/account/send reports/drop_sign.svg"
import RadioButton from '../account comps/reposts/RadioButton';
import classes from './MonthChoice.module.css'

const MonthChoice = (props) => {
    const {month, setMonth, style} = props
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <p className={style==1 ? classes.text : classes.text2}>Статистика за: {month}</p>
            <span>
                <img src={sign_up} alt="" style={isOpen ? {transform: "rotate(180deg)"} : {}} onClick={()=>setIsOpen(!isOpen)} className={style==1 ? classes.but : classes.but2}/>
            </span>
            <div style={{display: isOpen ? 'flex': 'none'}} className={ style==1 ? classes.dropcont : classes.dropcont2}>
                <span className={style==1 ? classes.dropline : classes.dropline2}>
                    <RadioButton butValue='1 месяц' currentValue={month} setValue={setMonth}/>
                    1 месяц
                </span>
                <span className={style==1 ? classes.dropline : classes.dropline2}>
                    <RadioButton butValue='3 месяца' currentValue={month} setValue={setMonth}/>
                    3 месяца
                </span>
            </div>
        </>
    );
};

export default MonthChoice;