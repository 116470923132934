import React, { useState } from "react";
import classes from "../../../../styles/desktop/account/EditingMain.module.css"
import contour from "../../../images/account/editing parts/input_contour.svg"
import contour_wrong from "../../../images/account/editing parts/confirmInput_contour_wrong.svg"

const EditInput = (props) => {
    const {placeholder, caption, type, value, action, isRight} = props
    return (
    <span className={classes.editInputCont}>
        <img src={isRight ? contour : (isRight !== undefined ? contour_wrong : contour)} className={classes.inputContour} />
        <input 
          value={value}
          className={classes.inputStyle} 
          placeholder={placeholder} 
          maxLength={100} 
          type={type} 
          style={type === "password" ? { fontSize: "22px" } : {}}
          onChange={(event) => {
            const newValue = event.target.value;
            action(newValue);
          }}
        />
        <a className={classes.inputCaption}>{caption}</a>
        {props.children}
    </span>
  );
};

export default EditInput;