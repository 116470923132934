import React, { useState } from "react";
import "../../styles/mobile/Rules.css"
import RulesButton from "../../components/mobile/RulesButton";
import man from "../../components/images/mobile/rules/man.svg"
import cat from "../../components/images/mobile/rules/cat.svg"

const Rules = ( ) => {
  return (
      <main className="mobile_rulesCont">
        <RulesButton/>
        <span>
          <img src={cat} alt="Cat" className="catImg"/>
          <img src={man} alt="Man" className="mobile_manImg"/>
        </span>
      </main>
  );
};

export default Rules;