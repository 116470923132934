import React, { useState } from "react";
import classes from '../../styles/mobile/account/Patterns.module.css'
import SocialMedia from "../services/templates/SocialMedia"
import SendReport from "../../components/mobile/templates/ReportButton";
import Arrow from "../../components/mobile/templates/Arrow";
import Pattern from "../services/templates/DynamicPattern";

const Patterns = ( ) => {
  const [patternNumb, setPatternNumb] = useState(0)
  const [patterns, setPatterns] = useState({});
  const [network, setNetwork] = useState(1)
  return (
      <main className={classes.mainContainer}>
        <SocialMedia setNetwork={setNetwork}/>
        <Pattern patternNumb={patternNumb} setPatternNumb={setPatternNumb} patterns={patterns} setPatterns={setPatterns} network={network}/>
        <SendReport />
        <Arrow type={"left"} setPatternNumb={setPatternNumb} patternNumb={patternNumb} patterns={patterns}/>
        <Arrow type={"right"} setPatternNumb={setPatternNumb} patternNumb={patternNumb} patterns={patterns}/>
      </main>
  );
};

export default Patterns;