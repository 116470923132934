import React, { useState, useEffect } from "react";
import "../../styles/mobile/account/UserProfile.css"
import elipse from "../../components/images/account/profile parts/profile_elips.svg";
import smile from "../../components/images/account/profile parts/smile.svg";
import edit from "../../components/images/account/profile parts/edit.svg";
import link_contour from "../../components/images/account/profile parts/copyLink_contour.svg";
import CopyButton from "../../components/mobile/profile/CopyButton";
import ExitButton from "../../components/mobile/profile/ExitButton";
import balance_contour from "../../components/images/account/profile parts/balance_contour.svg";
import balance_inside from "../../components/images/mobile/account/profile/balance_ins.svg";
import rec_inside from "../../components/images/mobile/account/profile/rec_ins.svg";
import ruble from "../../components/images/account/profile parts/ruble.svg";
import MonthChoice from "../../components/comps/month choice/MonthChoice";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const UserProfile = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [link, setLink] = useState('')
  const [month, setMonth] = useState('1 месяц')
  const [userData, setUserData] = useState({});

  const dataHandle = () => {
    const config = {
      headers: {
        Authorization: "Token "+token
      }
    };
    axios.get('/api/account/get_account_snippet/', config)
      .then(response => {
        // Обработка успешного ответа
        const userData = response.data;
        setUserData(userData);
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  };

  useEffect(() => {
    dataHandle()
    linkHandle()
  }, []);

  const exitHandle =()=> {
    const config = {
      method: 'post',
      headers: {
          Authorization: "Token " + token
      }
    };
    axios('/api/auth/logout/', config)
      .then(response => {
        // Обработка успешного ответа
        localStorage.removeItem("token");
        navigate('/')
        window.location.reload();
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  }

  const linkHandle =()=> {
    const config = {
      headers: {
          Authorization: "Token " + token
      }
    };
    axios.get('/api/account/get_main_bounty_link/', config)
      .then(response => {
        // Обработка успешного ответа
        const link = response.data.data.link;
        setLink(link)
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  }

  return (
    <div className="mobile_mainUserInfoContainer">
      <span class="mobile_editSign"><img src={edit} alt="Edit" 
        onClick={() => {
          navigate('/account/editing');
          window.location.reload(); // нужно чтобы фотобатон работал сразу
        }}/>
      </span>
      <div className="mobile_infoContainer">
        <span className="mobile_infoPhotoCont" onClick={()=>navigate('/account/editing')}>
          {userData.avatar_img === null ?(
            <span className="mobile_infoPhotoCont">
              <img src={elipse} className="mobile_infoElipse" alt="Elipse"/>
              <img src={smile} className="mobile_infoSmile" alt="Smile"/>
            </span>
          ) : (
            <img src={userData.avatar_img} className="infoElipse" />
          )}
        </span>
        <a className="mobile_infoTitle" onClick={()=>navigate('/account/editing')}>{userData.username ?? "Добавьте ник"}</a>
        <a className="mobile_infoText">{userData.phone_number}</a>
      </div>
      <div className="mobile_copyLinkContainer">
        <img src={link_contour} className="mobile_linkContour" />
        <a className="mobile_copyLinkTitle">Копировать реферальную ссылку<br/>на основной сайт компании MezEx</a>
        <CopyButton link={link}/>
      </div>
      <div className="mobile_squaresCont">
        <span className="mobile_balanceContainer">
          <img src={balance_inside} alt="Balance I" className="mobile_balanceInside" />
          <img src={balance_contour} alt="Balance C" className="mobile_balanceContour" />
          <a className="mobile_balanceTitle">Баланс:</a>
          <span className="mobile_balanceNumbsContainer">
            <a className="mobile_balanceNumbs">{userData.balance}</a>
            <img src={ruble} alt="Ruble" className="mobile_ruble" />
          </span>
        </span>
        <span className="mobile_balanceContainer">
          <img src={rec_inside} alt="Rec I" className="mobile_balanceInside" />
          <img src={balance_contour} alt="Rec C" className="mobile_balanceContour" />
          <a className="mobile_balanceTitle">Рекомендаций:</a>
          <span className="mobile_balanceNumbsContainer">
            <a className="mobile_balanceNumbs">{userData.recommendations}</a>
          </span>
        </span>
      </div>
      <div className="mobile_statisticContainer">
        <span className="mobile_statisticLine">
            <MonthChoice month={month} setMonth={setMonth}  style={2}/>
        </span>
        {month=='1 месяц' ? 
        (<>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Пользователей перешло по ссылке:</a>
              <a className="mobile_statisticNumbs">{userData.leads}</a>
          </span>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Пользователей обратилось в компанию:</a>
              <a className="mobile_statisticNumbs">{userData.target_leads}</a>
          </span>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Пользователей заключило договор:</a>
              <a className="mobile_statisticNumbs">{userData.contracts}</a>
          </span>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Сделок состоялось:</a>
              <a className="mobile_statisticNumbs">{userData.deals}</a>
          </span>
        </>) : (
          <>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Пользователей перешло по ссылке:</a>
              <a className="mobile_statisticNumbs">{userData.three_month_leads}</a>
          </span>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Пользователей обратилось в компанию:</a>
              <a className="mobile_statisticNumbs">{userData.three_month_target_leads}</a>
          </span>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Пользователей заключило договор:</a>
              <a className="mobile_statisticNumbs">{userData.three_month_contracts}</a>
          </span>
          <span className="mobile_statisticLine">
              <a className="mobile_statisticText">Сделок состоялось:</a>
              <a className="mobile_statisticNumbs">{userData.three_month_deals}</a>
          </span>
        </>
        )
        }
      </div>
      <ExitButton onClick={()=>exitHandle()}/>
    </div>
  );
};

export default UserProfile;