import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import classes from "../../../styles/desktop/form styles/Errors.module.css";
import attention_contour from '../../images/form parts/contours/attention_contour.svg';

const Attention = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
    },  10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <motion.div
      className={classes.errorNotification}
      initial={{
        y: -25,
        opacity: 0,
      }}
      animate={{
        y: 7,
        opacity: isVisible ? 1 : 0,
      }}
      transition={{
        duration: 0.7,
      }}
    >
      <span className={classes.attentionNotion}>Уведомление</span>
      <img src={attention_contour} className={classes.attentionImg} alt="Attention Icon" />
      <span className={classes.attentionCaption}>Обратите внимание!</span>
      <span className={classes.attentionText}>
        Для получения выплат по программе, номер телефона должен быть подключен к СБП.
      </span>
    </motion.div>
  );
};

export default Attention;