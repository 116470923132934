import React, { useEffect, useState } from 'react';
import "../../../../styles/desktop/account/SendReports.css"
import sign_up from "../../../images/account/send reports/drop_sign.svg"
import RadioButton from './RadioButton';
import axios from 'axios';

const DropdownButton = (props) => {
  const {selectedOption, setSelectedOption} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({})

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    console.log(option)
  };

  const dataHandle = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Token "+token
      }
    };
    axios.get('/api/main/get_action_list/', config)
      .then(response => {
        // Обработка успешного ответа
        const Data = response.data;
        setData(Data.data);
        setSelectedOption(Data.data[0].pk)
        console.log(Data.data)
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  };

  useEffect(() => {
    dataHandle()
  }, []);

  return (
    <div>
      <button className="dropButton" onClick={() => setIsOpen(!isOpen)}>
        <img src={sign_up} className='dropImg' style={isOpen ? {transform: "rotate(180deg)"} : {}}/>
      </button>
      {isOpen && (
        <div className="dropMenu">
          <ul>
            {data.map((item) => (
                <p className='dropLineStyle' key={item.pk}>
                    <RadioButton setValue={handleOptionChange} butValue={item.pk} currentValue={selectedOption} />
                    <label>{item.name}</label>
                </p>
            ))}
          </ul>
        </div>
      )}
      <a className='underDropTxt'>{data && !data.length == 0 ? "Выбрано: "+data[selectedOption - 1].name : ''}</a>
    </div>
  );
};

export default DropdownButton;