import React from "react";
import UserProfile from "../services/account/profile/UserProfile";
import '../../styles/desktop//account/MainAccount.css'
import LeftSideBar from "../services/account/LeftSideBar";
import SendReportsComp from "../services/account/reports/SendReportsComp";

const SendReports = ( ) => {
    return (
        <div className="left-side-main">
            <div className="left-side">
                <LeftSideBar/>
                <SendReportsComp/>
            </div>
            <UserProfile/>
        </div>
  );
};

export default SendReports;