import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import logo from '../../../components/images/main page/Header/logo.svg';
import menuIcon from '../../../components/images/mobile/Header/Menu.svg';
import elipse from "../../../components/images/account/profile parts/profile_elips.svg";
import smile from "../../../components/images/account/profile parts/smile.svg";

import '../../../styles/mobile/MainPage/HeaderMobile.css';
import '../../../styles/mobile/MainPage/HeaderMobileMenuNoLog.css';
import '../../../styles/mobile/MainPage/HeadeMobileMenuLogIn.css';

import MenuMan from '../../../components/images/mobile/Header/MenuMan.svg';
import AuthorizationButton from '../../../components/mobile/Header/AuthorizationButton';

const HeaderMobile = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({ avatar_img: null });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const dataHandle = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Token " + token
      }
    };
    axios.get('/api/account/get_avatar_img/', config)
      .then(response => {
        const userData = response.data;
        setUserData(userData);
      })
      .catch(error => {
        console.error(error.request.response);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      method: 'post',
      headers: {
        Authorization: "Token " + token
      }
    };

    if (token) {
      dataHandle();
      axios('/api/auth/check_auth/', config)
        .then(response => {
          setIsLoggedIn(true);
        })
        .catch(error => {
          localStorage.removeItem("token");
          window.location.reload();
        });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const getPageName = () => {
    switch (location.pathname) {
      case '/':
        return 'Главная';
      case '/account':
        return 'Личный кабинет';
      case '/account/editing':
        return 'Редактирование профиля';
      case '/rules':
        return 'Правила';
      case '/authorization':
        return 'Авторизация';
      case '/account/templates':
        return 'Шаблоны';
      case '/account/reports/send':
        return 'Отправить отчёт';
      default:
        return 'Страница';
    }
  };

  return (
    <>
      <div className="header-mobile">
        <img src={logo} alt="Logo" className="header-mobile-logo" />
        <div className="header-mobile-title">{getPageName()}</div>
        <div className="header-mobile-menu-icon" onClick={toggleMenu}>
          <img src={menuIcon} alt="Menu" />
        </div>
      </div>
      {isMenuOpen && (
        <div className="header-mobile-menu">
          <div className="header-mobile-menu-content">
            <button onClick={toggleMenu} className="header-mobile-menu-close">X</button>
            {isLoggedIn ? (
              <>
                <div className="top-side-log">
                  <div className="top-side-log-lk">
                    <div className="headerPhoto-mobile">
                      {userData.avatar_img === null ? (
                        <div className='header-photo-group-mobile'>
                          <img src={elipse} className="Elipse-mobile" alt="Elipse"/>
                          <img src={smile} className="Smile-mobile" alt="Smile"/>
                        </div>
                      ) : (
                        <div className='header-photo-group-mobile'>
                          <img src={userData.avatar_img} className="doneElipse-mobile" />
                        </div>
                      )}
                    </div>  
                    <NavLink exact to="/account" onClick={toggleMenu} className="nav-link">
                      <button className={location.pathname === '/account' ? "Personal-account-mobile active-link" : "Personal-account-mobile"}>Личный кабинет</button>
                    </NavLink>
                  </div>
                  <div className="top-side-log-menubar">
                    <div className="Main-but-container-mobile">
                      <NavLink exact to="/" onClick={toggleMenu} className="nav-link">
                        <button className={location.pathname === '/' ? "Main-but-mobile active-link" : "Main-but-mobile"}>Главная</button>
                      </NavLink>
                    </div>
                    <div className="Report-but-container-mobile">
                      <NavLink exact to="/account/reports/send" onClick={toggleMenu} className="nav-link">
                        <button className={location.pathname === '/account/reports/send' ? "Report-but-mobile active-link" : "Report-but-mobile"}>Отправить отчет</button>
                      </NavLink>
                    </div>
                    <div className="Templates-but-container-mobile">
                      <NavLink exact to="/account/templates" onClick={toggleMenu} className="nav-link">
                        <button className={location.pathname === '/account/templates' ? "Templates-but-mobile active-link" : "Templates-but-mobile"}>Шаблоны</button>
                      </NavLink>
                    </div>
                    <div className="Rule-but-container-mobile">
                      <NavLink exact to="/rules" onClick={toggleMenu} className="nav-link">
                        <button className={location.pathname === '/rules' ? "Rule-but-mobile active-link" : "Rule-but-mobile"}>Правила</button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="top-side-nolog">
                <AuthorizationButton toggleMenu={toggleMenu}/>         
                <div className="Main-but-container-mobile">
                  <NavLink exact to="/" onClick={toggleMenu} className="nav-link">
                    <button className={location.pathname === '/' ? "Main-but-mobile active-link" : "Main-but-mobile"}>Главная</button>
                  </NavLink>
                </div>
                <div className="Rule-but-container-mobile">
                  <NavLink exact to="/rules" onClick={toggleMenu} className="nav-link">
                    <button className={location.pathname === '/rules' ? "Rule-but-mobile active-link" : "Rule-but-mobile"}>Правила</button>
                  </NavLink>
                </div>
              </div>
            )}
            <div><img src={MenuMan} alt="Menu Man" /></div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMobile;
