import React, { useEffect, useState } from "react";
import classes from '../../../../styles/desktop/account/Patterns.module.css'
import Message from "../../../../components/comps/account comps/templates/Message";
import phone_left from '../../../../components/images/account/patterns parts/phone_contour.svg'
import phone_right from '../../../../components/images/account/patterns parts/phone_contour_story.svg'
import phone_footer from '../../../../components/images/account/patterns parts/phone_footer.png'
import CopyButton from "../../../../components/comps/account comps/templates/CopyButtonPattern";
import noStoryMan from '../../../../components/images/account/patterns parts/noStory_man.svg'
import noStoryMessage from '../../../../components/images/account/patterns parts/noStory_message.svg'
import Notification from "../../../../components/comps/account comps/templates/Notification";
import axios from "axios";

const Pattern = (props) => {
    const {patternNumb, setPatternNumb, patterns, setPatterns, network} = props
    const [showNotificText, setShowNotificText] = useState(false)
    const [showNotificStory, setShowNotificStory] = useState(false)
    const [icon, setIcon] = useState(null)

    const dataHandle = () => {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
          Authorization: "Token " + token,
          },
          params: {
            id: parseInt(network)
          }
        };
        axios.get('/api/account/get_bounty_templates_for_social_network/', config)
          .then(response => {
            // Обработка успешного ответа
            console.log("УСПЕХ")
            setPatternNumb(0) //обнуляем сдвиг шаблонов
            setPatterns(response.data.data.bounty_templates)
            setIcon(response.data.data.social_network_data.icon)
          })
          .catch(error => {
            // Обработка ошибки
            console.error(error.request.response);
          });
    };

    useEffect(() => {
      dataHandle()
    }, [network]);

    return (
        <React.Fragment>
        <div className={classes.phoneContainerLeft}>
          <Notification show={showNotificText} setShow={setShowNotificText}/>
            <img src={phone_left} alt="Phone Left" className={classes.phoneContour}/>
            <img src={phone_footer} alt="Phone footer" className={classes.phoneFooter}/>
            <div className={classes.header}>
                <img src={icon ? icon : null} className={classes.logo} />
                <a className={classes.headerTitle}>
                    {patterns[patternNumb] ? patterns[patternNumb].title : ''}
                </a>
            </div>
          <div className={classes.messagesContainer}>
            <Message 
                setShow={setShowNotificText}
                text = {patterns[patternNumb] ? patterns[patternNumb].description : 'Нет образца текста для данного шаблона.'}
                link = {patterns[patternNumb] ? patterns[patternNumb].bounty_link_with_uid : ''}
            />
          </div>
        </div>
        <div className={classes.phoneContainerRight}>
          <Notification  show={showNotificStory} setShow={setShowNotificStory}/>
          <img src={phone_right} alt="Phone Right" className={classes.phoneContour}/>
          {patterns[patternNumb] ? patterns[patternNumb].img ? 
            <img src={patterns[patternNumb].img} alt="Story" className={classes.story}/>
            :
            <div className={classes.noStoryCont}>
              <span className={classes.noStoryMessageCont}>
                <img src={noStoryMessage} className={classes.noStoryMessage}/>
                <a className={classes.noStoryText}>Для этой социальной сети нет шаблона историй</a>
              </span>
                <img src={noStoryMan} alt="No Story" className={classes.noStoryMan} />
            </div>
            :
            <div className={classes.noStoryCont}>
              <span className={classes.noStoryMessageCont}>
                <img src={noStoryMessage} className={classes.noStoryMessage}/>
                <a className={classes.noStoryText}>Для этой социальной сети нет шаблона историй</a>
              </span>
                <img src={noStoryMan} alt="No Story" className={classes.noStoryMan} />
            </div>
            }
            <CopyButton style={2} setShow={setShowNotificStory}/>
        </div>
        </React.Fragment>
    );
  };
  
  export default Pattern;