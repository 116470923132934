import axios from 'axios';
import React, { useState } from "react";
import contour from "../../images/mobile/Header/Auto_cont.svg";
import inside from "../../images/mobile/Header/AutoIsnide.svg"
import inside_hover from "../../images/mobile/Header/AutoInsideHover.svg";
import { NavLink } from 'react-router-dom';

const AuthorizationButton = ({ toggleMenu }) => {
    const [hover, setHover] = useState(false);
    const [clicked, setClicked] =useState(false);
    const pic = () => {
      return( 
        clicked ? inside_hover :
        hover ? inside_hover : inside
      );
    };
  

  
    return (
      <span className="mobile_autoButtonCont">
        <img src={pic()} alt="Inside" className="mobile_autoInside" />
        <img src={contour} alt="Contour" className="mobile_autoContour" />
        <div className='mobile_autoButton1'>
        <NavLink exact to="/authorization" onClick={toggleMenu}>
        <button
          onClick={()=>{
            setClicked(true)
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="mobile_autoButton"
        >
          Авторизоваться
        </button>
        </NavLink>
        </div>
      </span>
    );
};

export default AuthorizationButton;