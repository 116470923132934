import React, { useState } from "react";
import "../../styles/desktop/Rules.css"
import RulesButton from "../../components/comps/RulesButton";
import man from "../../components/images/rules/man.svg"

const Rules = ( ) => {
  return (
      <main className="rulesCont">
        <RulesButton/>
        <img src={man} alt="Man" className="manImg"/>
      </main>
  );
};

export default Rules;