import React, {useRef} from 'react';
import '../../styles/desktop/Main_page/Main_2_Form.css'

import discord from '../../components/images/main page/Main_2_Form/logo_main/logo-discord.svg'
import inst from '../../components/images/main page/Main_2_Form/logo_main/logo-inst.svg'
import OK from '../../components/images/main page/Main_2_Form/logo_main/logo-OK.svg'
import viber from '../../components/images/main page/Main_2_Form/logo_main/logo-viber.svg'
import vk from '../../components/images/main page/Main_2_Form/logo_main/logo-vk.svg'
import watsapp from '../../components/images/main page/Main_2_Form/logo_main/logo-watsapp.svg'
import tg from '../../components/images/main page/Main_2_Form/logo_main/logo-tg.svg'
import dzen from '../../components/images/main page/Main_2_Form/logo_main/logo-dzen.svg'

import arrow1 from '../../components/images/main page/Main_2_Form/arrows/arrow1.svg'
import arrow2 from '../../components/images/main page/Main_2_Form/arrows/arrow2.svg'
import arrow3 from '../../components/images/main page/Main_2_Form/arrows/arrow3.svg'
import arrow4 from '../../components/images/main page/Main_2_Form/arrows/arrow4.svg'

import Hat_man from '../../components/images/main page/Main_2_Form/Hat_man.svg'
import mini_phone from '../../components/images/main page/Main_2_Form/mini_phone.svg'
import left_message from '../../components/images/main page/Main_2_Form/left-message.svg'

import last_border from '../../components/images/main page/Main_2_Form/last_border.svg'

import money_man from '../../components/images/main page/Main_2_Form/money_man.svg'

import { forwardRef } from 'react';
import Main_1_Form from './Main_1_Form';



const Main_2_Form = forwardRef(function(props, ref) {

    


    return (
        
        <div  className={'Main2'} ref={ref}>
            <div ></div>
            <div className="Main2-1" >
                Отправляй рекомендации <br /> в чаты и истории
                <div className="logo-sqad">
                    <img src={tg} alt="" />
                    {/* <img src={inst} alt="" /> */}
                    <img src={watsapp} alt="" />
                    <img src={viber} alt="" />
                    <img src={OK} alt="" />
                    <img src={discord} alt="" />
                    <img src={vk} alt="" />             
                </div>
            </div>
            <img src={arrow1} alt="" className={'arrow1'}/>
            <div className="Main2-2">
                Делись репостами из наших социальных сетей
                <div className="logo-sqad2">
                    <img src={tg} alt="" />
                    <img src={dzen} alt="" />
                    <img src={vk} alt="" />   
                </div>
            </div>

            <img src={arrow2} alt="" className={'arrow2'}/>


            <div className="Main2-3">
                Отправляй скриншот <br />с сообщением/историей <br />и количеством подписчиков
            </div>

            <img src={arrow3} alt="" className={'arrow3'}/>

            <div className="Main2-4">
                <img src={money_man} alt="" />
                <p>Получай вознаграждение</p>
            </div>

            <img src={arrow4} alt="" className={'arrow4'}/>


            <div className="Main2-5">
                <img src={last_border} alt="" />
               <p className='text'> Если кто‑то из пользователей <br /> заключит сделку по вашей ссылке, <br />вы получите <span>дополнительное <br />вознаграждение!</span></p>
            </div>


            <div className="Main2-6">
                <img src={Hat_man} alt="" className='hat_man'/>
                <p className="text6"> Ищи шаблоны сообщений <br /> и историй в личном кабинете</p>
                <img className={'mini_phone'} src={mini_phone} alt="" />
                <img className={'left_message'} src={left_message} alt="" />
            </div>


            
            
            {/* <Main_1_Form reff={ref} /> */}

        </div>
    );
});

export default Main_2_Form;