import React, { useState } from "react"
import "../../../styles/mobile/form styles/Form.css"
import contour from "../../images/mobile/auth/reg_but_cont.svg"
import inside_hover from "../../images/mobile/auth/reg_but_hov.svg"
import inside from "../../images/mobile/auth/reg_but_ins.svg"

const AuthBut = (props) => {
  const { action, sign } = props;
  const [hover, setHover] = useState(false)

  const pic = () => {
    return hover ? inside_hover : inside;
  };

  const clickHandle =() => {
    setHover(!hover)
    action()
  }

  return (
    <span className="authButCont">
      {pic() && <img src={pic()} alt="Inside" className="authInside" />}
      <img src={contour} alt="Contour" className="authCont" />
      <button 
        onTouchStart={clickHandle}
        id="upload-btn" 
        className={'authButton'} 
      >
        {sign}
      </button>
    </span>
  );
};

export default AuthBut;