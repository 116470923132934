import React, { useState } from "react";
import contour from "../../../images/account/patterns parts/copyButton_contour.svg";
import contour2 from "../../../images/account/patterns parts/copyButton_contour_big.svg";
import classes from '../../../../styles/desktop/account/Patterns.module.css';
import phone_jpg from "../../../images/main page/Main_1_Form/MainPhone.jpg"

const CopyButton = (props) => {
  const {text, link, style, setShow} = props;
  
  const handleCopyText = () => {

    const textToCopy = `${text}\n${link}`;
    navigator.clipboard.writeText(textToCopy);
    
    // navigator.clipboard.writeText(
    //   `${text}\n${link}`
    // ).then (
    //   setShow(true)
    // )
    // .catch (
    //   console.log("не удалось")
    // )
  };

  const handleCopyImg = async (animal) => {
    const URL = `https://w.forfun.com/fetch/03/03f8cd3f6796daaacc1fe43ffb7704b7.jpeg`;
    try {
      const copiedImage = await fetch(URL);
      const blobData = await copiedImage.blob();
      const clipboardItemInput = new ClipboardItem({ 'image/png': blobData });
      navigator.clipboard.write([clipboardItemInput]);
      setShow(true);
    } catch (e) {
      console.log(e);
    }
  }
  
  return style === 1 ? (
      <span className={classes.copyButtonCont}>
        <button onClick={handleCopyText} className={classes.copyButton}>
          <img src={contour} alt="Contour" className={classes.copyContour} />
          Копировать
        </button>
      </span>
    ) : (
        <span className={classes.copyButtonCont2}>
            <button className={classes.copyButton2}>
            <img src={contour2} alt="Contour" className={classes.copyContour} />
              Скопируйте изображение
            </button>
        </span>
    );
  };
  
  export default CopyButton;