import React from "react";
import button_contour from "../../images/form parts/contours/button_contour.svg";
import "../../../styles/mobile/form styles/Form.css";

const Button = (props) => {
  return (
    <button
      className="mobile-formButton"
      onClick={props.onClick}
    >
      <img src={button_contour} className="mobile-buttonContour" alt="Button Contour"/>
      {props.sign}
    </button>
  );
};

export default Button;