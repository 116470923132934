import React, { useRef } from "react";
import InputMask from "react-input-mask";
import inputClasses from "../../../styles/desktop/form styles/FormInput.module.css"
import input_contour from "../../images/form parts/contours/input_contour.svg"
import input_contour_wrong from "../../images/form parts/contours/input_contour_wrong.svg"
import sign_right from "../../images/form parts/signs/sign_right.svg"
import sign_wrong from "../../images/form parts/signs/sign_wrong.svg"

const InputComponent = (props) => {
  const { inputValue, action, mask, type, placeholder, password, confirmPassword, style, setIsAttention, isCodeRight } = props;
  return (
    <span>
      {typeof mask === 'string' && (
        <InputMask
          type={type}
          mask={mask}
          value={inputValue}
          className={placeholder === "Подтвердите пароль" || placeholder === "Пароль" ? inputClasses.formInputPassword : inputClasses.formInput}
          style={type === "password" ? { fontSize: "15px" } : {}}
          onChange={(event) => {
            const newValue = event.target.value;
            action(newValue);
            if (setIsAttention) setIsAttention(true);
          }}
        />
      )}
      {typeof mask !== 'string' && (
        <input
          type={type}
          maxLength={"100"}
          value={inputValue}
          className={placeholder === "Подтвердите пароль" || placeholder === "Пароль" ? inputClasses.formInputPassword : inputClasses.formInput}
          style={type === "password" ? { fontSize: "15px" } : {}}
          onChange={(event) => {
            const newValue = event.target.value;
            action(newValue);
            if (setIsAttention) setIsAttention(true);
          }}
        />
      )}
      <span className={inputClasses.inputSign}>{placeholder}</span>

      {placeholder !== "Подтвердите пароль" && placeholder!== "Код из письма" ? (
        <img
          src={input_contour}
          className={inputClasses.inputVector}
          alt="Input Contour"
        />
      ) : placeholder === "Код из письма" ? (
        <img
          src={
            !isCodeRight
              ? input_contour_wrong
              : input_contour
          }
          className={inputClasses.inputVector}
          alt="Input Contour"
        />
      ) : (
        <div>
          <img
            src={
              password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword
                ? input_contour_wrong
                : input_contour
            }
            className={inputClasses.inputVector}
            alt="Input Contour"/>
          {password.length > 0 && confirmPassword.length > 0 && password === confirmPassword && (
              <img src={sign_right} className={inputClasses.confirmSigns} alt="Sign Right" />)}
          {password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword && (
              <img src={sign_wrong} className={inputClasses.confirmSigns} alt="Sign Wrong" />)}
        </div>
      )}
      {props.children}
  </span>
  );
};

export default InputComponent;