import React, { useState} from "react";
import '../../../../styles/desktop/form styles/Form.css';
import InputComponent from "../../../../components/comps/form comps/FormInput";
import Button from "../../../../components/comps/form comps/FormButton";
import Error from "../../../../components/comps/form comps/ErrorNotification";
import back from "../../../../components/images/form parts/signs/sign_back.svg";
import RecoveryEnterCode from "./RecoveryEnterCode";
import axios from "axios";

const RecoveryEnter = (props) => {
    const { number, setNumber, setIsEnter } = props;
    const [error, setError] = useState('');
    const [isGettingCode, setIsGettingCode] = useState(false)

    const validation = () => {
        if (!number.includes('_') && number.length !== 0){
            handleRecovery()
        } else {
            setError('Введите корректный номер телефона!');
        }
    };

    const handleRecovery = () => {
        const data = ({
            phone_number: number,
        });
  
        axios.post('/api/auth/recovery/', data)
        .then(response => {
            // Обработка успешного ответа
            setIsGettingCode(true)
            console.log("Код подтверждения отправлен.");
        })
        .catch(error => {
            // Обработка ошибки
            setError('Пользователя с таким номером нет.');
            console.error(error.request.response);
        });
    };

    return !isGettingCode ? (
        <div className="formContainer">
            <Error error={error} setError={setError} type="error"/>
            <img src={back} className="backSign" onClick={() => setIsEnter(true)} alt="Back"/>
            <a href="#" onClick={() => setIsEnter(true)} className="formLink" style={{right: "73px", marginTop: "10px"}}>Назад</a>
            <span className="formTtle">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</span>
            <InputComponent inputValue={number} action={setNumber} mask="+7 999 999 99 99" placeholder="Телефон"/>
            <Button className="formButton" onClick={()=>validation()} sign="Получить код"/>
        </div>
    ) : (
        <RecoveryEnterCode setIsGettingCode={setIsGettingCode} setIsEnter={setIsEnter} number={number}/>
    );
};

export default RecoveryEnter;