import React from 'react';
import AppDesktop from './AppDesktop';
import AppMobile from './AppMobile';
import { isMobile, isTablet } from 'react-device-detect';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function App() {
  return (
    isMobile || isTablet ? (
      <AppMobile/>
    ) : (
      <AppDesktop/>
    ))
}

export default App;