import React, { useState } from "react"
import '../../../../styles/desktop/form styles/Form.css';
import {motion} from 'framer-motion'
import leftHand_countur from "../../../../components/images/mobile/auth/left_hand_cont.svg"
import leftHand_inside from "../../../../components/images/mobile/auth/left_hand_ins.svg"
import man from "../../../../components/images/mobile/auth/man.svg"
import MainEnter from "./MainEnter";
import RecoveryEnter from "./RecoveryEnter";

const EnterForm = (props) => {
    const {animState} = props
    const [isEnter, setIsEnter] = useState(true);
    const [isRecovered, setIsRecovered] = useState(false)
    const [number, setNumber] = useState('')

    return (
        <>
            <motion.img src={man} alt="Man" 
                style={{position: 'absolute', left: '0', top: '60px', display:  !animState ? '' : 'none'}} 
                initial={{ scaleX: -1 }}
                animate={!animState ? { x: 0 } : { x: 800 }}
            />
            <motion.div className="mobile-mainFormContainer"
                animate={!animState ? { x: 0 } : { x: 800 }}
                style={!animState ? { } : { display: "none"}}
            >
                <motion.img src={leftHand_countur} alt="Right Hand Countur" style={{position: 'absolute', zIndex: -1}} initial={{ x: 9, y: 0, scaleX:-1 }}/>
                <motion.img src={leftHand_inside} alt="Right Hand Inside" style={{position: 'absolute', zIndex: -2}} initial={{ x: 9, y: 0, scaleX:-1 }}/>

                {isEnter ? (
                    <MainEnter setIsEnter={setIsEnter} number={number} setNumber={setNumber} isRecovered={isRecovered} setIsRecovered={setIsRecovered}/>
                ) : (
                    <RecoveryEnter setIsEnter={setIsEnter} number={number} setNumber={setNumber} setIsRecovered={setIsRecovered}/>
                )}
            </motion.div>
        </>
    )
}

export default EnterForm