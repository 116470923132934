import React, { useState } from "react";
import "../../../styles/desktop/form styles/Form.css";
import right from "../../images/form parts/signs/sign_right_black.svg"

const RadioButton = (props) => {
    const {agree, setAgree} = props
    const pic = () => {
        return agree ? right : null;
    };
    return (
    <span className="agreementCont">
        <button className="agreementRadioBut" onClick={()=>setAgree(!agree)}>
            <img src={pic()} alt="Radio But" className="agreementSign"/>
        </button>
        <a className="agreementText">
            Я принимаю <a className="agreementHref" href="https://documents.themezex.com/themezex" target="_blank">Положение и Согласие</a> на обработку персональных данных
        </a>
    </span>
    );
};

export default RadioButton;