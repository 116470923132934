import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import AccountWallet from "./AccountWallet";
import AccountPatterns from "./AccountPatterns";
import AccountReports from "./AccountReports";
import SendReports from "./AccountSendReports";
import EditingMain from "./EditingMain";
import EditingPassword from "./EditingPassword";

const Account = () => {
    const token = localStorage.getItem("token");
    
    if (!token) {
        return <Navigate to="/authorization" />;
    }
    
    return (
        <Routes>
            <Route path="/" element={<AccountWallet />} />
            <Route path="/reports" element={<AccountReports />} />
            <Route path="/reports/send" element={<SendReports />} />
            <Route path="/templates" element={<AccountPatterns />} />
            <Route path="/editing" element={<EditingMain />} />
            <Route path="/editing/password" element={<EditingPassword />} />
        </Routes>
    );
};

export default Account;