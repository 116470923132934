import React, { useState, useEffect } from "react"
import '../../../../styles/desktop/form styles/Form.css';
import InputComponent from "../../../../components/mobile/form comps/FormInput"
import Button from "../../../../components/mobile/form comps/FormButton"
import EyeComponent from "../../../../components/mobile/form comps/EyeComponent";
import Error from "../../../../components/mobile/form comps/ErrorNotification";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const MainEnter = (props) => {
    const { number, setNumber, setIsEnter } = props;
    const [password, setPassword] = useState('');
    const [inputType, setInputType] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const validation = () => {
        if ( !number.includes('_') && number.length !== 0) {
            if (password.length!==0){
                handleEnterNumber()
            } else {
                setError('Введите пароль!');
            }
        } else if ( number.includes('_')){
            setError('Введите корректный номер телефона!');
        } 
        else if (number.length === 0){
            setError('Введите номер телефона!');
        }
    };

    const handleEnterNumber = () => {
        const cleanedNumber = number.replace(/ /g, '');
        const data = ({
            phone_number: cleanedNumber,
            password: password,
        });
  
        axios.post('/api/auth/login/phone_number/', data)
        .then(response => {
            // Обработка успешного ответа
            navigate('/account/templates');
            window.location.reload();
            console.log(response.data);
            const token = response.data.token;
            localStorage.setItem('token', token);
        })
        .catch(error => {
            // Обработка ошибки
            setError('Неверный номер или пароль!');
            console.error(error.request.response);
        });
    };

    return (
        <div className="mobile-formContainer">
            <Error error={error} setError={setError} type={'error'} />
            <span className="mobile-formTtle">ВХОД</span>
            <InputComponent inputValue={number} action={setNumber} mask="+7 999 999 99 99" placeholder="Телефон"/>
            <InputComponent inputValue={password} action={setPassword} type={inputType === true ? "text" : "password"} placeholder="Пароль">
                <EyeComponent inputType={inputType} action={() => setInputType(!inputType)} />
            </InputComponent>
            <a href="#" className="mobile-formLink" style={{ left: "38px"}} onClick={() => setIsEnter(false)}>Забыли пароль?</a>
            <Button className="mobile-formButton" onClick={validation} sign="Войти" />
        </div>
    );
};

export default MainEnter;