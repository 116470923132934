import React, { useState, useEffect } from 'react';
import '../../../styles/desktop/form styles/Form.css';

const Timer = (props) => {
  const { initialSeconds, action } = props;
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isTimer, setIsTimer] = useState(true);
  let interval;

  useEffect(() => {
    interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setIsTimer(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (!isTimer) {
      clearInterval(interval);
    }
  }, [isTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const formattedSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${formattedSeconds.toString().padStart(2, '0')}`;
  };

  const handleResend = () => {
    action();
    setSeconds(initialSeconds);
    setIsTimer(true);
  };

  return (
    <React.Fragment>
      {!isTimer ? (
        <a href="#" className="formLink" style={{ paddingLeft: "110px"}} onClick={handleResend}>
          Отправить еще раз
        </a>
      ) : (
        <a className="formLink" style={{ paddingLeft: "60px"}}>
          Отправить еще раз через {formatTime(seconds)}
        </a>
      )}
    </React.Fragment>
  );
};

export default Timer;