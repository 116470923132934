import React, { useState } from "react";
import contour from "../../images/account/profile parts/button_contour.svg";
import inside from "../../images/account/profile parts/button_inside.svg";
import inside_hover from "../../images/account/profile parts/button_inside_hover.svg";
import "../../../styles/desktop/account/UserProfile.css";

const CopyButton = (props) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] =useState(false);
  const pic = () => {
    return( 
      clicked ? inside_hover :
      hover ? inside_hover : inside
    );
  };

  const handleCopy = () => {
    const textToCopy = props.link;
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <span className="copyButtonCont">
      <img src={pic()} alt="Inside" className="copyInside" />
      <img src={contour} alt="Contour" className="copyContour" />
      <button
        onClick={()=>{
          handleCopy()
          setClicked(true)
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="copyButton"
      >
        {clicked ? "Скопировано" : "Копировать"}
      </button>
    </span>
  );
};

export default CopyButton;