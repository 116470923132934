import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './desktop/services/Header';
import Footer from './desktop/services/Footer';
import Authorization from './mobile/pages/Authorization';
import Account from './mobile/pages/Account'
import axios from 'axios';
import FooterMobile from './mobile/services/Main/FooterMobile';
import '../src/styles/reset.css'
import HeaderMobile from './mobile/services/Main/HeaderMobile';
import MainMobile from './mobile/pages/MainMobile';
import Rules from './mobile/pages/Rules';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const AppMobile = () => {
  const location = useLocation(); // Получаем текущий маршрут

  return (
    <>
      <HeaderMobile currentPage={location.pathname} /> {/* Передаем текущий маршрут в Header */}
      <Routes>
        <Route path="/" element={<MainMobile />} />
        <Route path="/authorization" element={<Authorization/>} />
        <Route path="/account/*" element={<Account/>} />
        <Route path="/rules" element={<Rules/>} />
      </Routes>
      <FooterMobile />
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <AppMobile />
  </Router>
);

export default AppWrapper;