import React from "react";
import List from "../../reports/List";
import '../../../../styles/desktop/account/List.css'

const Reports = ( ) => {
    return (
        <main>
          <List/>
        </main>
  );
};

export default Reports;