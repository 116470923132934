import React, { useState } from "react";
import "../../../../styles/desktop/form styles/Form.css";
import right from "../../../images/form parts/signs/sign_right_black.svg"

const RadioButton = (props) => {
    const {agree, setAgree} = props
    const pic = () => {
        return agree ? right : null;
    };
    return (
        <button className="agreementRadioBut" onClick={()=>setAgree(!agree)} style={{marginLeft: "10px"}}>
            <img src={pic()} alt="Radio But" className="agreementSign"/>
        </button>
    );
};

export default RadioButton;