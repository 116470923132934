import React, { useState } from "react"
import '../../../../styles/desktop/form styles/Form.css';
import {motion} from 'framer-motion'
import rightHand_countur from "../../../../components/images/hands/right_contour.svg"
import rightHand_inside from "../../../../components/images/hands/right_inside.svg"
import MainEnter from "./MainEnter";
import RecoveryEnter from "./RecoveryEnter";

const EnterForm = (props) => {
    const [isEnter, setIsEnter] = useState(true);
    const [isRecovered, setIsRecovered] = useState(false)
    const [number, setNumber] = useState('')

    return (
        <motion.div className="mainFormContainer"
            animate={props.animState === 1 ? { scale: 1.6, y: 80, x:50, rotate: 0} : {rotate: 10, y: 192, x: 80 }}            
            onClick={() => props.setAnimState(1)}
        >
            <motion.img src={rightHand_countur} alt="Right Hand Countur" style={{position: 'absolute', zIndex: -1}} initial={{scale: 1.1, x: 6, y: 60, rotate: -10}}/>
            <motion.img src={rightHand_inside} alt="Right Hand Inside" style={{position: 'absolute', zIndex: -2}} initial={{scale: 1.1, x: 6, y: 60, rotate: -10}}/>

            {isEnter ? (
                <MainEnter setIsEnter={setIsEnter} number={number} setNumber={setNumber} isRecovered={isRecovered} setIsRecovered={setIsRecovered}/>
            ) : (
                <RecoveryEnter setIsEnter={setIsEnter} number={number} setNumber={setNumber} setIsRecovered={setIsRecovered}/>
            )}
        </motion.div>
    )
}

export default EnterForm