import React, { forwardRef } from 'react';
import '../../../styles/mobile/MainPage/Main_2_Form_mobile.css'

import discord from '../../../components/images/main page/Main_2_Form/logo_main/logo-discord.svg'
import inst from '../../../components/images/main page/Main_2_Form/logo_main/logo-inst.svg'
import OK from '../../../components/images/main page/Main_2_Form/logo_main/logo-OK.svg'
import viber from '../../../components/images/main page/Main_2_Form/logo_main/logo-viber.svg'
import vk from '../../../components/images/main page/Main_2_Form/logo_main/logo-vk.svg'
import watsapp from '../../../components/images/main page/Main_2_Form/logo_main/logo-watsapp.svg'
import tg from '../../../components/images/main page/Main_2_Form/logo_main/logo-tg.svg'
import dzen from '../../../components/images/main page/Main_2_Form/logo_main/logo-dzen.svg'

import one from '../../../components/images/mobile/Main_page/1.svg'
import three from '../../../components/images/mobile/Main_page/2.svg'
import two from '../../../components/images/mobile/Main_page/3.svg'

import obvod from '../../../components/images/mobile/Main_page/Main2formBig.svg'

import money from '../../../components/images/mobile/Main_page/money.svg'

const Main_2_Form_Mobile = forwardRef(function(props, ref) {
    return (
        <div className={'Main2_mobile'} ref={ref}>
             <div className="Main2-1-mobile" >
                <img src={one} alt="" className='numberOne'/>
                Отправляй рекомендации <br /> в чаты и истории
                <div className="logo-sqad-mobile">
                    <img src={tg} alt="" />
                    {/* <img src={inst} alt="" /> */}
                    <img src={watsapp} alt="" />
                    <img src={viber} alt="" />
                    <img src={OK} alt="" />
                    <img src={discord} alt="" />
                    <img src={vk} alt="" />             
                </div>
                <a className='orText-mobile'>или</a>
                Делись репостами из наших социальных сетей
                <div className="logo-sqad2-mobile">
                    <img src={tg} alt="" />
                    <img src={dzen} alt="" />
                    <img src={vk} alt="" />   
                </div>
                
            </div>

            <div className="Main2-2-mobile">
                <img src={two} alt="" className='numberTwo'/>
                Отправляй скриншот <br />с сообщением/историей <br />и количеством подписчиков
            </div>

            <div className="Main2-3-mobile">
                <img src={three} alt="" className='numberThree'/>
                <div className="">
                    <p>Получай вознаграждение</p>
                    <img src={money} alt="" className='money'/>
                </div>

            </div>

            <div className="Main2-4-mobile">
                <img src={obvod} alt="" className='obvod'/>
               <p> Если кто‑то из пользователей <br /> заключит сделку по вашей ссылке, <br />вы получите <span>дополнительное <br />вознаграждение!</span></p>
            </div>
        </div>
    );
});

export default Main_2_Form_Mobile;