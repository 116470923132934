import React, { useState, useEffect } from "react";
import '../../../../styles/desktop/form styles/Form.css';
import InputComponent from "../../../../components/comps/form comps/FormInput";
import Button from "../../../../components/comps/form comps/FormButton";
import Timer from "../../../../components/comps/form comps/Timer";
import Error from "../../../../components/comps/form comps/ErrorNotification";
import back from "../../../../components/images/form parts/signs/sign_back.svg";
import RecoveryPassword from "./RecoveryEnterPasword";
import axios from "axios";

const RecoveryEnterCode = (props) => {
    const { number, setIsGettingCode, setIsEnter} = props;
    const [code, setCode] = useState('');
    const [isCodeRight, setIsCodeRight] = useState(true)
    const [error, setError] = useState('Введите полученный код.');
    const [type, setType] = useState("message");
    const [isRecovering, setIsRecovering] = useState(false)


    const handleRecoveryCode = () => {
        const data = ({
            phone_number: number,
            otp: code
        });
  
        axios.post('/api/auth/recovery/verify_phone_number/', data)
        .then(response => {
            // Обработка успешного ответа
            setIsRecovering(true)
            console.log("Код подтверждения проверен.");
        })
        .catch(error => {
            // Обработка ошибки
            setIsCodeRight(false)
            setError('Введен неправильный код.');
            setType("error");
            console.error(error.request.response);
        });
    };

    const sendAgain = () => {
        const data = ({
            phone_number: number
        });
  
        axios.post('/api/auth/recovery/', data)
        .then(response => {
            // Обработка успешного ответа
            setError('Введите полученный код.');
            setType("message");
            console.log("Код подтверждения отправлен.");
        })
        .catch(error => {
            // Обработка ошибки
            setError('Такой почты не существует.');
            setType("error");
            console.error(error);
        });
    }

    return !isRecovering ? (
        <div className="formContainer">
            <Error error={error} setError={setError} type={type}/>
            <img src={back} onClick={() => setIsGettingCode(false)} className="backSign" />
            <a href="#" onClick={() => setIsGettingCode(false)} className="formLink" style={{right: "73px", marginTop: "10px"}}>Назад</a>
            <span className="formTtle" style={{marginTop: "30px"}}>ВОССТАНОВЛЕНИЕ ПАРОЛЯ</span>
            <span className="formText">Отправили код на <br/> {number}</span>
            <InputComponent inputValue={code} action={setCode} placeholder="Код из письма" isCodeRight={isCodeRight}/>
            <Timer initialSeconds={parseInt("60")} action={sendAgain}/>
            <Button className="formButton" onClick={()=>handleRecoveryCode()} sign="Отправить"/>
        </div>
    ) : (
        <RecoveryPassword number={number} setIsGettingCode={setIsGettingCode} setIsEnter={setIsEnter} setIsRecovering={setIsRecovering} code={code}/>
    );
};

export default RecoveryEnterCode;