import React from "react";
import classes from '../../../../styles/desktop/account/Patterns.module.css';
import message_contour from '../../../../components/images/account/patterns parts/messageBig.svg'
import CopyButton from "./CopyButtonPattern";

const Message = (props) => {
  const {text, link, setShow} = props

  return (
    <>
      <img src={message_contour} alt="Message Contour" className={classes.messageContour}/>
      <span className={classes.messageContainer}>
        <a className={classes.meaasgeText}>
          {text}<br/>{link}
        </a>
        <CopyButton text={text} link={link} style={1} setShow={setShow}/>
      </span>
    </>
  );
};

export default Message;