import React, { useEffect, useState } from "react";
import "../../../../styles/desktop/account/SendReports.css";
import contour from "../../../images/account/send reports/add_photo_contour.svg";
import inside_hover from "../../../images/account/send reports/add_photo_hover.svg";

const PhotoButton = (props) => {
  const { setPhoto } = props;
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside_hover : null;
  };

  const token = localStorage.getItem("token");
  const config = {
      headers: {
      Authorization: "Token " + token
      }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      setPhoto(file)
    } else if (file){
      alert("Выберите изображение размером не более 10 МБ");
    }
  };

  useEffect(() => {
    const uploadFile = document.getElementById("upload-file")
    const uploadBtn = document.getElementById("upload-btn")
    const uploadText = document.getElementById("upload-text")

    const handleClick = () => {
      uploadFile.click();
    };

    const handleChange = () => {
      if (uploadFile.value) {
        try {
          uploadText.innerText = uploadFile.value.match(/[\/\\]([\w\d\s\.\-(\)]+)$/u)[1];
        } catch (error) {
          alert("Ошибка: " + error);
        }
      } else {
        uploadText.innerText = "Файл не выбран";
      }
    };

    uploadBtn.addEventListener("click", handleClick);
    uploadFile.addEventListener("change", handleChange);

    return () => {
      uploadBtn.removeEventListener("click", handleClick);
      uploadFile.removeEventListener("change", handleChange);
    };
  }, []); 

  return (
    <span className="photoButtonCont">
      <input id="upload-file" type="file" accept="image/*" onChange={handleFileUpload} hidden="hidden" />
      {pic() && <img src={pic()} alt="Inside" className="photoInside" />}
      <img src={contour} alt="Contour" className="photoContour" />
      <button 
        id="upload-btn" 
        className={`photoButton ${hover ? 'hoveredPhotoButton' : ''}`} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
      >
        Прикрепить фото
      </button>
      <a id="upload-text" style={{display: "none"}}>Файл не загружен</a>
    </span>
  );
};

export default PhotoButton;