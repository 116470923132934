import React, { useState } from "react";
import contour from "../../images/mobile/account/profile/copy_but_cont.svg";
import inside from "../../images/mobile/account/profile/copy_but_ins.svg"
import inside_hover from "../../images/mobile/account/profile/copy_but_ins.svg";
import "../../../styles/mobile/account/UserProfile.css"

const CopyButton = (props) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] =useState(false);
  const pic = () => {
    return( 
      clicked ? inside_hover :
      hover ? inside_hover : inside
    );
  };

  const handleCopy = () => {
    const textToCopy = props.link;
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <span className="mobile_copyButtonCont">
      <img src={pic()} alt="Inside" className="mobile_copyInside" />
      <img src={contour} alt="Contour" className="mobile_copyContour" />
      <button
        onClick={()=>{
          handleCopy()
          setClicked(true)
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="mobile_copyButton"
      >
        {clicked ? "Скопировано" : "Копировать"}
      </button>
    </span>
  );
};

export default CopyButton;