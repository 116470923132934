import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import classes from "../../../styles/desktop/form styles/Errors.module.css";
import error_contour from "../../images/form parts/contours/error_contour.svg";
import sign_wrong from "../../images/form parts/signs/sign_wrong.svg";
import sign_right from "../../images/form parts/signs/sign_right.svg";

const Error = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const {error, setError, type} = props

  useEffect(() => {
    if (error !== "") {
      setIsVisible(true);
  
      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          setError("");
        }, 600);
      }, 2000);
  
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <React.Fragment>
      {error === "" ? (
        <span />
      ) : (
        <motion.div
          className={classes.errorNotification}
          initial={{
            y: -40,
            opacity: 0,
          }}
          animate={{
            y: -30,
            opacity: isVisible ? 1 : 0,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <span className={classes.errorNotion}>Уведомление</span>
          <img src={error_contour} className={classes.errorImg} />
          <img src={type==="error" ? sign_wrong : sign_right} className={classes.errorSign} />
          <span className={classes.errorCaption}>{type === "error" ? 'Ошибка' : 'Вам пришло СМС.'}</span>
          <span className={classes.errorText}>{error}</span>
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default Error;