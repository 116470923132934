import React, { useEffect, useState } from "react";
import "../../../../styles/desktop/account/SendReports.css"
import PhotoButton from "../../../../components/comps/account comps/reposts/AddPhotoBut";
import BufferButton from "../../../../components/comps/account comps/reposts/BufferButton";
import AddReportBut from "../../../../components/comps/account comps/reposts/AddReportBut";
import AddInfo from "../../../../components/comps/account comps/reposts/AddInfo";
import arrow from "../../../../components/images/account/send reports/arrow.svg";
import delete_photo from "../../../../components/images/account/send reports/delete_photo.svg"
import man from "../../../../components/images/account/send reports/man.svg"
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const SendReportsComp = ( ) => {
    const navigate = useNavigate();
    const [photo, setPhoto] = useState(null)
    const [photos, setPhotos] = useState([])
    const [addOptions, setAddOptions] = useState({
        pk: 0,
        subtype: 0
    })

    const sendData = () => {
        const token = localStorage.getItem("token");
        const config = {
            headers: {
            Authorization: "Token " + token
            }
        };

        const formData = new FormData();
            photos.forEach((image, index) => {
                formData.append("uploaded_images", image);
        });
        formData.append("request_action_pk", addOptions.pk)
        formData.append("request_action_subtype", addOptions.subtype)

        axios.post('/api/account/upload_report/', formData, config)
        .then(response => {
            // Обработка успешного ответа
            setPhotos([])
            navigate('/account/reports')
        })
        .catch(error => {
            // Обработка ошибки
            alert("Неверный формат фотографий.")
            console.error(error.request.response);
        });
    };

    useEffect(() => {
        if (photo!==null)
            setPhotos((prevPhotos) => [...prevPhotos, photo]);
    }, [photo]);

    const deletePhoto = (index) => {
        setPhotos((prevPhotos) => {
          const updatedPhotos = [...prevPhotos];
          updatedPhotos.splice(index, 1);
          return updatedPhotos;
        });
    };

    return (
        <main className="mainCont">
            <div className="addFileCont">
                <PhotoButton setPhoto={setPhoto}/>
                {/* <a className="orText">или</a>
                <BufferButton setPhoto={setPhoto}/> */}
            </div>
            <a className="yoursPhotos">Ваши фото</a>
            <img src={arrow} className="arrow" alt="Arrow"/>
            <div className="photos">
            {photos.length === 0 ? (
                <p style={{width: "400px"}}>Вы не добавили ни одного фото :(</p>
            ) : (
                photos.map((photo, index) => (
                    <span key={index} className="photoCont">
                      {photo instanceof File ? (
                        <img src={URL.createObjectURL(photo)} className="photo" />
                      ) : (
                        <img src={photo} className="photo" />
                      )}
                      <img src={delete_photo} className="deletePhoto" onClick={() => deletePhoto(index)} />
                    </span>
                ))
            )}
            </div>
            <AddInfo setAddOptions={setAddOptions}/>
            <AddReportBut sendData={sendData}/>
            <div className="downCont">
                <a className="rulesText">
                    Правила оформления скриншотов:<br/><br/>
                    При публикации сообщений:<br/>
                    На скриншоте должно быть видно ваше сообщение и количество<br/>
                    участников беседы/сообщества. При невозможности размещения данных<br/>
                    {"на одном скриншоте — сделайте два :)"}<br/><br/>
                    При публикации историй:<br/>
                    Необходимо прикрепить скриншот истории и скриншот вашего профиля —<br/>
                    для отслеживания количества подписчиков.<br/><br/>
                    Не обрезайте скриншоты! Экран должен быть захвачен полностью.<br/><br/>
                    Один отчёт = одно рекомендательное сообщение/история!<br/><br/>
                    Подробнее с правилами можете ознакомиться{' '}
                    <a href="/rules">здесь</a>.
                </a>
                <img src={man} className="man"/>
            </div>
        </main>
  );
};

export default SendReportsComp;