import React from "react";
import eye_closed from "../../images/form parts/signs/eye_closed.svg"
import eye_opened from "../../images/form parts/signs/eye_opened.svg"
import classes from "../../../styles/mobile/account/EditingPassword.module.css"

const Eye = (props) => {
    const {inputType, action} = props
    return (
      <img 
          src={inputType ? eye_opened : eye_closed} alt="Eye Icon"
          className={inputType ? classes.eyeStyleOpen: classes.eyeStyleClose}
          onClick={action}
      />   
    );
};

export default Eye;