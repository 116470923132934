import React, { useState } from "react";
import "../../styles/mobile/Rules.css"
import inside from "../images/rules/but_inside.svg"
import inside_hover from "../images/rules/but_hover.svg"

const RulesButton = () => {
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside_hover : inside;
  };

  const Look = () => {
    const link = document.createElement('a');
    link.href = "https://docs.google.com/document/d/11ZDDj1GkY3bFHPWGR1uAON6OO0G43GzdB0KDtFJAhkY/edit";
    link.target="_blank"
    link.click();
  };

  return (
    <span className="mobile_rulesButCont">
        <img src={pic()} alt="Inside" className="mobile_rulesImg"/>
      <button
        onClick={Look}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="mobile_rulesBut"
      >
        Ознакомиться<br/>с правилами
      </button>
    </span>
  );
};

export default RulesButton;