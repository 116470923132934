import React, { useEffect, useState } from "react";
import classes from '../../styles/desktop/account/EditingMain.module.css'
import PhotoButton from "../../components/comps/account comps/editing/PhotoButton";
import Input from "../../components/comps/account comps/editing/Input";
import PasswordButton from "../../components/comps/account comps/editing/PasswordButton";
import ConfirmButton from "../../components/comps/account comps/editing/ConfirmButton";
import hatMan from "../../components/images/account/editing parts/hatMan.svg"
import message_vector from "../../components/images/account/editing parts/message.svg"
import man_body from "../../components/images/account/editing parts/man_body.svg"
import elipse from "../../components/images/account/profile parts/profile_elips.svg";
import smile from "../../components/images/account/profile parts/smile.svg";
import back from "../../components/images/form parts/signs/sign_back.svg";
import EditingEmail from "../services/account/profile/EditingEmail";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const EditingMain = () => {
    const navigate = useNavigate();
    const [nikname, setNikname] = useState('')
    const [messageSeen, setMessageSeen] = useState(false)
    const [message, setMessage] = useState('')
    const [photo, setPhoto] = useState(null)

    const token = localStorage.getItem("token");
    const config = {
        headers: {
        Authorization: "Token " + token
        }
    };

    const validation=()=> {
        if (photo!==null)
            {avatarChanger()}
            
        if (nikname!=='' && nikname.length>2 && nikname.length<30)
            {niknameChanger()}
        else if (nikname!==''&& nikname.length<3)
            {
                setMessage('Длина никнейма должна быть\nболее 3 символов!')
                setMessageSeen(true)
            }
        else if (nikname.length>=20)
            {
                setMessage('Длина никнейма должна быть\nне более 30 символов!')
                setMessageSeen(true)
            }

        setTimeout(() => {
            window.location.reload();
        }, 2000);

    }

    const [userData, setUserData] = useState({ });
    
    const dataHandle = () => {
        axios.get('/api/account/get_account_snippet/', config)
          .then(response => {
            // Обработка успешного ответа
            const userData = response.data;
            setUserData(userData);
            console.log("Успешно получены данные.")
          })
          .catch(error => {
            // Обработка ошибки
            console.error(error.request.response);
          });
    };
    
    useEffect(() => {
        dataHandle(); // Запускаем функцию при загрузке страницы
    }, []);

    const avatarChanger = () => {
        const data = new FormData();
        data.append("avatar_img", photo);
      
        axios.post('/api/account/change_avatar_img/', data, config)
          .then(response => {
            // Handle the successful response
            console.log("Успешно сменен аватар.");
          })
          .catch(error => {
            // Handle the error
            setMessage('Некоректный формат изображения.\nНужна картинка до 10мб.')
            setMessageSeen(true)
            console.error(error.request.response);
          });
    }

    const niknameChanger = () => {
        const data = { username: nikname };
        console.log(data)
      
        axios.post('/api/account/change_username/', data, config)
          .then(response => {
            // Handle the successful response
            setMessageSeen(false)
            console.log("Успешно сменен никнейм.");
          })
          .catch(error => {
            // Handle the error
            console.error(error.request.response);
          });
    }

    return (
        <main className={classes.helpContainer}>
            <div className={classes.editingBody}>
                <span className={classes.backCont}>
                    <img src={back} alt="Back Sign" className={classes.backSign}/>
                    <a href="../account">Назад</a>
                </span>
                <a className={classes.editingTitle}>РЕДАКТИРОВАНИЕ ПРОФИЛЯ</a>
                <div className={classes.editingContainer}>
                    <PhotoButton setPhoto={setPhoto}/>
                    <Input placeholder={userData.username==='' ? "Добавьте ник" : userData.username} caption="Ник" value={nikname} action={setNikname}/>
                    <PasswordButton onClick={() => navigate('/account/editing/password')} />
                    <ConfirmButton onClick={()=>validation()}/>
                </div>
                <img src={hatMan} alt="Hat Man" className={classes.hatMan} />
                <span className={classes.messageCont} style={{ display: userData.avatar_img ? 'none' : 'flex' }}>
                    <img src={message_vector} className={classes.messageVector} />
                    <a style={{ zIndex: "0" }}>Здесь могло быть Ваше фото</a>
                </span>
                <span className={classes.messageCont} style={{ display: messageSeen ? 'flex' : 'none' , fontSize: "15px"}}>
                    <img src={message_vector} className={classes.messageVector} />
                    <a style={{ zIndex: 0 }}>{message}</a>
                </span>
                <div className={classes.manCont}>
                    <img src={man_body} className={classes.manBody} />
                    <span className={classes.headCont}>
                        {userData.avatar_img ? (
                            <img src={userData.avatar_img} className={classes.headElipse}/>
                        ) : (
                            <>
                            <img src={elipse} className={classes.headElipse} />
                            <img src={smile} className={classes.headSmile} />
                            </>
                        )}
                    </span>
                </div>
            </div>
        </main>
    );
};

export default EditingMain;