import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './desktop/services/Header';
import Footer from './desktop/services/Footer';
import Main from './desktop/pages/Main';
import Authorization from './desktop/pages/Authorization';
import Account from './desktop/pages/Account'
import Rules from './desktop/pages/Rules';


function AppDesktop() {

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/authorization" element={<Authorization />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/account/*" element={<Account />} /> {/* Используется вложенный маршрут */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default AppDesktop;