import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import sign_right from "../../images/form parts/signs/sign_right.svg";
import inside from "../../images/account/patterns parts/notification.svg"
import classes from "../../../styles/mobile/account/Patterns.module.css"

const Notification = (props) => {
  const {show, setShow} = props
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);

      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          setShow(false);
        }, 600);
      }, 4000);
  
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <React.Fragment>
      {!show ? (
        <span />
      ) : (
        <motion.div
          className={classes.notificCont}
          initial={{
            y: -10,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: isVisible ? 1 : 0,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <img src={inside} className={classes.notificImg} />
          <a className={classes.notificNotion}>Уведомление</a>
          <img src={sign_right} className={classes.notificSign} />
          <a className={classes.notificCaption}>{'Шаблон успешно скопирован'}</a>
          <a className={classes.notificText}>После отправки прикрипите отчет по кнопке ниже.</a>
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default Notification;