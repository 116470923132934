import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import '../../../styles/desktop/account/CustomCalendar.css'

const MyCalendar = () => {
    const [date, setDate] = useState(new Date());
    const [userData, setUserData] = useState({
      "1": '',
      "2": '',
      "3": '',
      "7": '',
      "9": ''
    });

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(selectedDate.getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
    

    const handleDateChange = (date) => {
      setSelectedDate(date);
      console.log("date ", date.getMonth() + 1)
      setSelectedMonth(date.getMonth() + 1);
      setSelectedYear(date.getFullYear());
      console.log("month:", (selectedMonth));
      console.log("year:",  (selectedYear))  
      fetchData()
    };

    const handleViewChange = ({ activeStartDate }) => {
      
      setSelectedDate(activeStartDate)
      const popa = activeStartDate.getMonth()+1
      console.log("popa", popa)
      setSelectedMonth(popa);
      console.log("jopa", activeStartDate.getMonth()+1)
      setSelectedYear(activeStartDate.getFullYear());

      const firstOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);
      const lastOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 0);

      const firstDayOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);
      const firstDayOfWeek = firstDayOfMonth.getDay(); // Воскресенье - 0, Суббота - 6
      const daysInMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 0).getDate();

      const weeks = (firstDayOfWeek === 6 && daysInMonth === 31) || (firstDayOfWeek === 0 && daysInMonth >= 30);
      console.log("week", weeks)
      if (weeks){
        console.log("tratata")
      }
      setWeekCount(weeks);
      console.log(weekCount)

      // fetchData();
         
    };

    const getWeeksBetweenDates = (startDate, endDate) => {
      const weeks = Math.ceil((endDate - startDate) / (7 * 24 * 60 * 60 * 1000));
      console.log(endDate)
      console.log(startDate)
      console.log(Math.ceil((endDate - startDate) / (7 * 24 * 60 * 60 * 1000)))
      return weeks;
    };


    useEffect(() => {
      if (selectedMonth) {
        const month = selectedDate.getMonth() + 1;
        setSelectedMonth(month);
        setSelectedYear(selectedDate.getFullYear());
        console.log("month:", selectedMonth);
        console.log("year:", selectedYear);
        fetchData();
      }
    }, [selectedDate]);
    
    const fetchData = () => {
      const token = localStorage.getItem("token");
      
        const config = {
          headers: {
          Authorization: "Token " + token,
          },
          params: {
            month: parseInt(selectedMonth),
            year: parseInt(selectedYear)
          }
        };
        axios.get(`/api/account/get_all_earnings_per_month/`, config)
          .then(response =>{
            // Обработка ответа
            console.log(response.data);
            const userData = response.data.data;
            setUserData(userData);
          })

          .catch (error =>{
            console.error('Ошибка при получении данных:', error);
            console.error(error.response.data);
          }) 

      
    };

    const renderDay = (props) => {
      const { date, view } = props;
      const isMonthView = view === 'month';
  
      return (
        <div className={`react-calendar__tile ${isMonthView ? 'react-calendar__month-view__days__day' : ''}`}>
          {isMonthView && (
            <>
              <div className="day-number">
                {date.getDate()}
              </div>
              <div className="day-name">
                {getDayName(date.getDay())}
              </div>
            </>
          )}
        </div>
      );
    };
  
    const getDayName = (dayIndex) => {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      return daysOfWeek[dayIndex];
    };

    const tileContent = ({ date, view }) => {
          // Получаем номер текущего месяца
          const currentMonth = selectedMonth;
          const monthOfDate = date.getMonth() + 1;
        // Проверяем, что отображаем дни в месячном виде
        if (monthOfDate === selectedMonth) {
        // Возвращает пользовательский текст для дней, когда это необходимо
        const day = date.getDate();
        
        if (userData[day]) {
            return <p className='day-info' >+{userData[day]}</p>;
        }
        }
        // Возвращаем null для всех остальных дней
        return null;
    };

    const daysOfWeek = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
    const formatShortWeekday = (locale, date) => {
      return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date).substring(0, 2);
    };

    useEffect(()=>{
      fetchData()
    }, [])

    const [weekCount, setWeekCount] = useState(false);

    return (
        <div className='calendar-text-container'> 
        
      
        <Calendar
          className={`custom-calendar-${weekCount === false ? 'five-weeks' : 'six-weeks'}`}
          // monthClassName  = {`pppppppppppppppppppppppppppppppppppppppp`}
          value={date}
          onChange={handleDateChange}
          onActiveStartDateChange={handleViewChange}
          // onClickMonth={handleMonthChange}
          tileContent={tileContent}
          formatShortWeekday={formatShortWeekday}
          renderDay={renderDay}
          
        />
              {/* <p>Выбранная дата: {selectedDate.toDateString()}</p>
      <p>Выбранный месяц: {weekCount}</p>
      <p>Выбранный год: {selectedYear}</p> */}
        <p className='downcalendarinfo'>Выплаты производятся с 1 по 5 число каждого месяца по номеру телефона, указанному<br /> в личном кабинете</p>
      </div>
    );

};

export default MyCalendar;