import React, { useState } from "react"
import '../../../../styles/desktop/form styles/Form.css'
import leftHand_countur from "../../../../components/images/hands/left_contour.svg"
import leftHand_inside from "../../../../components/images/hands/left_inside.svg"
import MainRegistration from "./MainRegistration"
import ConfirmRegistration from "./ConfirmRegistration"
import {motion} from 'framer-motion'

const RegistrationForm = (props) => {
    const [isRegistration, setIsRegistration] = useState(true);
    const [number, setNumber] = useState('')

    return (
        <motion.div className="mainFormContainer"
            animate={props.animState === 0 ? { scale: 1.6, y: 80, x:80, rotate: 0} : {rotate: -10, y: 190, x: -50 }}
            onClick={() => {props.setAnimState(0)}}
        >
            <motion.img src={leftHand_countur} alt="Left Hand Countur" style={{position: 'absolute', zIndex: -1}} initial={{scale: 1.1, x: -6, y: 60, rotate: 10}}/>
            <motion.img src={leftHand_inside} alt="Left Hand Inside" style={{position: 'absolute', zIndex: -2}} initial={{scale: 1.1, x: -6, y: 60, rotate: 10}}/>

            {isRegistration ? (
                <MainRegistration setIsRegistration={setIsRegistration} number={number} setNumber={setNumber}/>
            ) : (
                <ConfirmRegistration setIsRegistration={setIsRegistration}  number={number}/>
            )}
            
        </motion.div>
    );
}

export default RegistrationForm;