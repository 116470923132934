import React, { useState } from "react"
import '../../../../styles/desktop/form styles/Form.css'
import leftHand_countur from "../../../../components/images/mobile/auth/left_hand_cont.svg"
import leftHand_inside from "../../../../components/images/mobile/auth/left_hand_ins.svg"
import MainRegistration from "./MainRegistration"
import ConfirmRegistration from "./ConfirmRegistration"
import man from "../../../../components/images/mobile/auth/man.svg"
import {motion} from 'framer-motion'

const RegistrationForm = (props) => {
    const {animState } = props
    const [isRegistration, setIsRegistration] = useState(true);
    const [number, setNumber] = useState('')

    return (
        <>
            <motion.img src={man} alt="Man" 
                style={{position: 'absolute', right: '0', top: '60px', display:  animState ? '' : 'none'}} 
                animate={animState ? { x: 0} : { x: -800 }}
            />
            <motion.div className="mobile-mainFormContainer"
                animate={animState ? { x: 0} : { x: -800 }}
                style={animState ? { } : { display: "none"}}
            >
                <motion.img src={leftHand_countur} alt="Left Hand Countur" style={{position: 'absolute', zIndex: -1}} initial={{ x: -9, y: 0 }}/>
                <motion.img src={leftHand_inside} alt="Left Hand Inside" style={{position: 'absolute', zIndex: -2}} initial={{ x: -9, y: 0 }}/>

                {isRegistration ? (
                    <MainRegistration setIsRegistration={setIsRegistration} number={number} setNumber={setNumber} />
                ) : (
                    <ConfirmRegistration setIsRegistration={setIsRegistration}  number={number} />
                )}
                
            </motion.div>
        </>
    );
}

export default RegistrationForm;