import React, { useState, useEffect } from "react";
import '../../../../styles/desktop/form styles/Form.css';
import back from "../../../../components/images/form parts/signs/sign_back.svg";
import InputComponent from "../../../../components/comps/form comps/FormInput";
import Button from "../../../../components/comps/form comps/FormButton";
import Timer from "../../../../components/comps/form comps/Timer";
import Error from "../../../../components/comps/form comps/ErrorNotification";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ConfirmRegistration = (props) => {
    const { number, setIsRegistration } = props;
    const [code, setCode] = useState('');
    const [error, setError] = useState('Введите полученный код.');
    const [type, setType] = useState("message");
    const [isCodeRight, setIsCodeRight] = useState(true)
    const navigate = useNavigate();

    const handleRegistration = () => {
        const data = ({
            phone_number: number,
            otp: code
        });
  
        axios.post('/api/auth/register/verify_phone_number/', data)
        .then(response => {
            // Обработка успешного ответа
            setCode('');
            setIsCodeRight(true)
            navigate('/account/templates');
            window.location.reload();
            const token = response.data.token;
            localStorage.setItem('token', token);
            console.log(response.request.response);
        })
        .catch(error => {
            // Обработка ошибки
            setIsCodeRight(false)
            setError('Введен неправильный код.');
            setType("error");
            console.error(error.request.response);
        });
    };

    const sendAgain = () => {
        const data = ({
            phone_number: number,
        });

        axios.post('/api/auth/register/resend_phone_number_otp/', data)
        .then(response => {
            // Обработка успешного ответа
            setError('Введите полученный код.');
            setType("message");
            console.log(response.data);
        })
        .catch(error => {
            // Обработка ошибки
            setError('Такой почты не существует.');
            setType("error");
            console.error(error.request.response);
        });
    }

    return (
        <div className="formContainer">
            <Error error={error} setError={setError} type={type}/>
            <img src={back} onClick={() => setIsRegistration(true)} className="backSign"/>
            <a href="#" onClick={() => setIsRegistration(true)} className="formLink" style={{right: "73px", marginTop: "10px"}}>Назад</a>
            <span className="formTtle">ПОДТВЕРЖДЕНИЕ РЕГИСТРАЦИИ</span>
            <span className="formText">Отправили код на <br/> {number}</span>
            <InputComponent inputValue={code} action={setCode} placeholder="Код из письма" isCodeRight={isCodeRight}/>
            <Timer initialSeconds={parseInt("60")} action={sendAgain}/>
            <Button className="formButton" onClick={()=>handleRegistration()} sign="Отправить"/>
        </div>
    );
};

export default ConfirmRegistration;