import React, { useRef } from 'react';
import phone from '../../../components/images/mobile/Main_page/MainPhoneMobile.svg'
// import ButtonMain1 from '../../components/comps/ButtonMain1';
import '../../../styles/mobile/MainPage/Main_1_Form_mobile.css'
import ButtonMain1_mobile from '../../../components/mobile/Main_page/ButtonMain1_mobile';

const Main_1_Form_Mobile = ({block2Ref}) => {
    return (
        <div>
            <div className={'Main1_mobile'}>
            

                <img src={phone} className='MainPhone1_mobile' alt="Phone" />
                <div className={"maintext_mobile"}>
                    ЗАРАБАТЫВАЙ <br /> НА РЕПОСТАХ <br /> И РЕКОМЕНДАЦИЯХ
                </div>
                <ButtonMain1_mobile block2Ref={block2Ref}/>
                
            </div>
        </div>
    );
};

export default Main_1_Form_Mobile;