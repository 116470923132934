import React, { useRef } from 'react';
import Main_1_Form_Mobile from '../services/Main/Main_1_Form_Mobile';
import Main_2_Form_Mobile from '../services/Main/Main_2_Form_Mobile';
import Main_3_Form_Mobile from '../services/Main/Main_3_Form_Mobile';
import Team_button_mobile from '../services/Main/Team_button_mobile';

const MainMobile = () => {
    
    const block2Ref = useRef(null);
    return (
        <div>
            <Main_1_Form_Mobile block2Ref={block2Ref}/>
            <Main_2_Form_Mobile ref={block2Ref} />
            <Main_3_Form_Mobile/>
            <Team_button_mobile/>
            
        </div>
    );
};

export default MainMobile;