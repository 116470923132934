import React, { useState } from "react";
import classes from '../../styles/desktop/account/EditingPassword.module.css'
import Input from "../../components/comps/account comps/editing/Input";
import ConfirmButton from "../../components/comps/account comps/editing/ConfirmButton";
import ConfirmInput from "../../components/comps/account comps/editing/ConfirmInput";
import Eye from "../../components/comps/account comps/editing/EyeComp";
import hatMan from "../../components/images/account/editing parts/hatMan.svg"
import speakMan from "../../components/images/account/editing parts/speak_man.svg"
import speakManSad from "../../components/images/account/editing parts/speak_man_sad.svg"
import message_vector from "../../components/images/account/editing parts/bigMessage.svg"
import back from "../../components/images/form parts/signs/sign_back.svg";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const EditingPassword = () => {
    const navigate = useNavigate();
    const [isRightOld, setIsRightOld] = useState(true)
    const [inputTypeOld, setInputTypeOld] = useState(false)
    const [inputTypeNew, setInputTypeNew] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('Придумайте сложный пароль!\nМинимальная длина — 8 символов.')

    const token = localStorage.getItem("token");
    const config = {
        headers: {
        Authorization: "Token " + token
        }
    };

    const validation = () => {
        if (oldPassword==='')
            {setMessage('Введите старый пароль.')}
        else if (newPassword==='')
            {setMessage('Введите новый пароль.')}
        else if (confirmPassword==='')
            {setMessage('Введите подтверждение пароля.')}
        else if (newPassword!=='' && newPassword.length<8)
            {setMessage('Минимальная длина пароля — 8 символов.')}
        else if (newPassword!=='' && confirmPassword!=='' && newPassword!==confirmPassword)
            {setMessage('Пароли не совпадают!\nПопробуйте еще раз.')}
        else if (confirmPassword!=='' && newPassword!=='' && confirmPassword===newPassword)
            {passwordChanger()}
    }

    const passwordChanger = () => {
        const data = { 
            old_password: oldPassword,
            password: newPassword,
            password2: confirmPassword
        };
      
        axios.post('/api/account/change_password/', data, config)
          .then(response => {
            //успех
            console.log("Успешно сменен пароль.");
            navigate('/account/editing')
          })
          .catch(error => {
            //ошибка
            setIsRightOld(false)
            {setMessage('Неверный старый пароль.')}
            console.error(error.request.response);
          });
    }

    return (
        <main className={classes.helpContainer}>
        <div className={classes.editingBody}>
            <span className={classes.backCont}>
                    <img src={back} alt="Back Sign" className={classes.backSign}/>
                    <a href="../editing">Назад</a>
            </span>
            <a className={classes.editingTitle}>РЕДАКТИРОВАНИЕ ПАРОЛЯ</a>
            <div className={classes.editingContainer}>
                <Input type = {inputTypeOld ? "text":"password"} isOldRight={isRightOld} caption="Старый пароль"
                    value={oldPassword} action={setOldPassword} isRight={isRightOld}>
                    <Eye inputType = {inputTypeOld} action = {() => setInputTypeOld(!inputTypeOld)}/>
                </Input>
                <Input type = {inputTypeNew ? "text":"password"} caption="Новый пароль"
                    value={newPassword} action={setNewPassword}>
                    <Eye inputType = {inputTypeNew} action = {() => setInputTypeNew(!inputTypeNew)}/>
                </Input>
                <ConfirmInput caption="Подтвердите новый пароль"
                    value={confirmPassword} action={setConfirmPassword}
                    confirmPassword={confirmPassword} newPassword={newPassword}/>
                <ConfirmButton style={{marginLeft: "7px"}} onClick={()=>validation()}/>
            </div>
            <img src={hatMan} alt="Hat Man" className={classes.hatMan}/>
            <img src={message==="Придумайте сложный пароль!\nМинимальная длина — 8 символов." ? speakMan : speakManSad} alt="Speak Man" className={classes.speakMan}/>
            <span className={classes.messageCont}>
                <img src={message_vector} className={classes.messageVector}/>
                <a style={{ zIndex: "0", whiteSpace: "pre-line" }}>
                    {message}
                </a>
            </span>
        </div>
        </main>
  );
};
export default EditingPassword;