import React from "react";
import UserProfile from "../services/account/profile/UserProfile";
import Patterns from "../services/account/tepmlates/Patterns";
import '../../styles/desktop/account/MainAccount.css'
import LeftSideBar from "../services/account/LeftSideBar";

const AccountPatterns = ( ) => {
    return (
        <div className="left-side-main">
             <div className="left-side">
                <LeftSideBar/>
                <Patterns/>
            </div>
            
            <UserProfile/>
        </div>
  );
};

export default AccountPatterns;