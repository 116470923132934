import React, { useState } from "react";
import contour from "../../images/account/patterns parts/copyButton_contour.svg";
import contour2 from "../../images/account/patterns parts/copyButton_contour_big.svg";
import classes from '../../../styles/mobile/account/Patterns.module.css';
import phone_jpg from "../../images/main page/Main_1_Form/MainPhone.jpg";

const CopyButton = (props) => {
  const { text, link, style, setShow, story } = props;

  const handleCopyText = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(
        `${text}\n${link}`
      ).then(
        () => setShow(true)
      ).catch(
        () => console.log("не удалось")
      );
    } else {
      console.log("Clipboard API не поддерживается на этом устройстве");
    }
  };

  const handleDownloadImg = () => {
    const a = document.createElement('a');
    a.href = story;
    a.download = 'photo.jpg';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return style === 1 ? (
    <span className={classes.copyButtonCont}>
      <button onClick={handleCopyText} className={classes.copyButton}>
        <img src={contour} alt="Contour" className={classes.copyContour} />
        Копировать
      </button>
    </span>
  ) : (
    <span className={classes.copyButtonCont2}>
      <button onClick={handleDownloadImg} className={classes.copyButton2}>
        <img src={contour2} alt="Contour" className={classes.copyContour} />
        Скачать
      </button>
    </span>
  );
};

export default CopyButton;
