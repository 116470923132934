import React from 'react';
import styles from '../../../styles/mobile/MainPage/Footer.module.css'

const FooterMobile = () => {
    return (
        <div className={styles.Footer}>
            <div className={styles.infooter}>
                <p>Общество с ограниченной ответственностью "Мезекс. Информационные системы" (ООО "Мезекс.Информационные системы").</p>
                <p>IT-компания, решение о предоставлении государственной аккредитации организации, осуществляющей деятельность в области информационных технологий от 17.05.2022 No АО-20220517-4564781889-3. Товарный знак (знак обслуживания) No 804635.</p>
                <p>ОГРН 1225900009411, ИНН 5904401319, юридический адрес: 614010, Пермский край, г. Пермь, ул. Куйбышева, д. 95б, офис 1201/6.</p>
                <p>Информация на сайте не является публичной офертой и носит информационно-справочный характер. Все права защищены.</p>
                <a href="https://drive.google.com/file/d/1wak7MhbJEJc8NhyIebGA7nK-aRWz_lRF/view"> Согласие посетителя сайта на обработку персональных данных.</a>
            </div>
            <div className={styles.right_side_footer}>
                <p><b>Контакты</b> </p>
                <p>Отдел по работе с клиентами  <br />8 (342) 277–00–75</p>
                <p>По общим вопросам <br />8 (342) 259–27–73</p>
                <p>themezex@gmail.com</p>
            </div>

        
        </div>
    );
};

export default FooterMobile;