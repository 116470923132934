import React from "react";
import eye_closed from "../../images/form parts/signs/eye_closed.svg"
import eye_opened from "../../images/form parts/signs/eye_opened.svg"
import inputClasses from "../../../styles/desktop/form styles/FormInput.module.css"

const EyeComponent = (props) => {
  const {inputType, action} = props
  return (
    <img 
        src={inputType ? eye_opened : eye_closed} alt="Eye Icon"
        className={inputType ? inputClasses.eyeStyleOpened : inputClasses.eyeStyleClosed}
        onClick={action}
    />   
  );
};

export default EyeComponent;