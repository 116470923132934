import React, { useEffect, useState } from "react";
import classes from "../../../styles/mobile/account/EditingMain.module.css";
import contour from "../../images/account/editing parts/photoButton_contour.svg";
import inside from "../../images/mobile/account/editing/photoBut_ins.svg";
import inside_hover from "../../images/account/editing parts/photoButton_hover.svg";

const PhotoButton = (props) => {
  const { setPhoto } = props;
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside_hover : inside;
  };

  const token = localStorage.getItem("token");
  const config = {
      headers: {
      Authorization: "Token " + token
      }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      setPhoto(file)
    } else {
      alert("Выберите изображение размером не более 10 МБ");
    }
  };

  useEffect(() => {
    const handleClick = () => {
      const uploadFile = document.getElementById("upload-file");
      uploadFile.click();
    };
  
    const handleChange = () => {
      const uploadFile = document.getElementById("upload-file");
      const uploadText = document.getElementById("upload-text");
  
      if (uploadFile.value) {
        try {
          uploadText.innerText = uploadFile.value.match(/[\/\\]([\w\d\s\.\-(\)]+)$/u)[1];
        } catch (error) {
          alert("Ошибка: " + error);
        }
      } else {
        uploadText.innerText = "Файл не выбран";
      }
    };
  
    const uploadBtn = document.getElementById("upload-btn");
    const uploadFile = document.getElementById("upload-file");
    const uploadText = document.getElementById("upload-text");
  
    uploadBtn.addEventListener("click", handleClick);
    uploadFile.addEventListener("change", handleChange);
  
    return () => {
      uploadBtn.removeEventListener("click", handleClick);
      uploadFile.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <React.Fragment>
    <span className={classes.photoButtonCont}>
      <input id="upload-file" type="file" accept="image/*" onChange={handleFileUpload} hidden="hidden"/>
      <img src={pic()} alt="Inside" className={classes.photoInside} />
      <img src={contour} alt="Contour" className={classes.photoContour} />
      <button id="upload-btn" className={classes.photoButton} 
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        Изменить фото профиля
      </button>
    </span>
      <a id="upload-text" className={classes.photoLabel}>Файл не загружен</a>
    </React.Fragment>
  );
};

export default PhotoButton;