import React, { useState } from "react";
import contour from "../../../images/account/patterns parts/reportBut_contour.svg";
import inside from "../../../images/account/patterns parts/reportBut_inside.svg";
import inside_hover from "../../../images/account/patterns parts/reportBut_hover.svg";
import classes from '../../../../styles/desktop/account/Patterns.module.css';
import { useNavigate } from 'react-router-dom';

const SendReport = (props) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside_hover : inside;
  };

  return (
    <span className={classes.reportButtonCont}>
      <img src={pic()} alt="Inside" className={classes.reportInside} />
      <img src={contour} alt="Contour" className={classes.reportContour} />
      <button
        onClick={()=>{
          navigate('/account/reports/send')
          window.location.reload()
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={classes.reportButton}
      >
        Отправить отчет
      </button>
    </span>
  );
};

export default SendReport;