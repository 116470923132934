import React, { useState } from "react";
import classes from "../../../../styles/desktop/account/EditingMain.module.css"
import contour from "../../../images/account/editing parts/passwordButton_contour.svg"
import inside from "../../../images/account/editing parts/passwordButton_inside.svg"
import inside_hover from "../../../images/account/editing parts/passwordButton_hover.svg"

const PasswordButton = (props) => {
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside_hover : inside;
  };

  return (
    <span className={classes.passwordButtonCont}>
      <img src={pic()} alt="Inside" className={classes.passwordInside} />
      <img src={contour} alt="Contour" className={classes.passwordContour} />
      <button onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.passwordButton}>
        Изменить пароль
      </button>
    </span>
  );
};

export default PasswordButton;