import React from "react";
import UserProfile from "../services/account/profile/UserProfile";
import '../../styles/desktop/account/MainAccount.css'
import MyCalendar from "../services/account/MyCalendar";
import LeftSideBar from "../services/account/LeftSideBar";

const AccountWallet = ( ) => {
    return (
        <div className="left-side-main">
            <div className="left-side">
                <LeftSideBar/>
                <MyCalendar/>
            </div>
            <UserProfile/>
        </div>
  );
};

export default AccountWallet;