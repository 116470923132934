import React, { useEffect, useState } from "react";
import "../../styles/mobile/account/SendReports.css"
import PhotoButton from "../../components/mobile/send reports/AddPhotoBut";
import AddReportBut from "../../components/mobile/send reports/AddReportBut";
import AddInfo from "../../components/mobile/send reports/AddInfo";
import arrow from "../../components/images/account/send reports/arrow.svg";
import delete_photo from "../../components/images/account/send reports/delete_photo.svg"
import man from "../../components/images/account/send reports/man.svg"
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const SendReportsComp = ( ) => {
    const navigate = useNavigate();
    const [photo, setPhoto] = useState(null)
    const [photos, setPhotos] = useState([])
    const [addOptions, setAddOptions] = useState({
        pk: 0,
        subtype: 0
    })

    const sendData = () => {
        const token = localStorage.getItem("token");
        const config = {
            headers: {
            Authorization: "Token " + token
            }
        };

        const formData = new FormData();
            photos.forEach((image, index) => {
                formData.append("uploaded_images", image);
        });
        formData.append("request_action_pk", addOptions.pk)
        formData.append("request_action_subtype", addOptions.subtype)

        axios.post('/api/account/upload_report/', formData, config)
        .then(response => {
            // Обработка успешного ответа
            setPhotos([])
            navigate('/account/templates')
        })
        .catch(error => {
            // Обработка ошибки
            alert("Неверный формат фотографий.")
            console.error(error.request.response);
        });
    };

    useEffect(() => {
        if (photo!==null)
            setPhotos((prevPhotos) => [...prevPhotos, photo]);
    }, [photo]);

    const deletePhoto = (index) => {
        setPhotos((prevPhotos) => {
          const updatedPhotos = [...prevPhotos];
          updatedPhotos.splice(index, 1);
          return updatedPhotos;
        });
    };

    return (
        <main className="mobile_mainCont">
            <PhotoButton setPhoto={setPhoto}/>
            <a className="mobile_yoursPhotos">Ваши фото</a>
            <img src={arrow} className="mobile_arrow" alt="Arrow"/>
            <div className="mobile_photos">
            {photos.length === 0 ? (
                <p style={{width: "150%"}}>Вы не добавили ни одного фото :(</p>
                ) : (
                    photos.map((photo, index) => (
                    <span key={index} className="mobile_photoCont">
                        {photo instanceof File ? (
                            <img src={URL.createObjectURL(photo)} className="photo" />
                            ) : (
                                <img src={photo} className="photo" />
                            )}
                        <img src={delete_photo} className="mobile_deletePhoto" onClick={() => deletePhoto(index)} />
                    </span>
                ))
            )}
            </div>
            <AddInfo setAddOptions={setAddOptions}/>
            <AddReportBut sendData={sendData}/>
            <a className="mobile_rulesText">
                Правила оформления скриншотов:<br/><br/>
                При публикации сообщений:<br/>
                На скриншоте должно быть видно ваше
                сообщение и количество участников
                беседы/сообщества. При невозможности размещения данных
                {"на одном скриншоте — сделайте два :)"}<br/><br/>
                При публикации историй:<br/>
                Необходимо прикрепить скриншот истории и скриншот вашего профиля —
                для отслеживания количества подписчиков.<br/><br/>
                Не обрезайте скриншоты! Экран должен быть захвачен полностью.<br/><br/>
                Один отчёт = одно рекомендательное сообщение/история или несколько
                персональных сообщений.<br/><br/>
                Подробнее с правилами можете ознакомиться{' '}
                <a href="/rules">здесь</a>.
            </a>
        </main>
  );
};

export default SendReportsComp;