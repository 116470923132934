import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import '../../styles/desktop/Main_page/Header.css';
import ButtonHeader from '../../components/comps/ButtonHeader';
import logo from '../../components/images/main page/Header/logo.svg';
import elipse from "../../components/images/account/profile parts/profile_elips.svg";
import smile from "../../components/images/account/profile parts/smile.svg";

const Header = () => {

  const [userData, setUserData] = useState({
    avatar_img: null,
  });

  const dataHandle = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Token "+token
      }
    };
    axios.get('/api/account/get_avatar_img/', config)
      .then(response => {
        // Обработка успешного ответа
        const userData = response.data;
        setUserData(userData);
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      method: 'post',
      headers: {
        Authorization: "Token "+token
      }
    };
    
    if (token) {
      dataHandle()
      
      console.log("Token "+token)
      // Если токен есть, проверяем его на валидность и устанавливаем состояние авторизации в true
      axios('/api/auth/check_auth/', config)
      .then(response => {
        // Обработка успешного ответа
        setIsLoggedIn(true);
        console.log("токен годен!")
        console.log(response)
      })
      .catch(error => {
        // Обработка ошибки
        localStorage.removeItem("token");
        console.log("токен не годен..")
        window.location.reload();
      });
    } else {
      // Если токена нет, устанавливаем состояние авторизации в false
      setIsLoggedIn(false);
    }
  }, []);

 

  return (
    <div className="header">
      <img src={logo} alt="" className='header-logo'/>
      <div className="Main-but-container">
        <NavLink exact="true" to="/">
          <button className="Main-but">Главная</button>
        </NavLink>
      </div>
    
      <div className="Rule-but-container">
          <NavLink exact="true" to="/rules">
            <button className="Rule-but">Правила</button>
          </NavLink>
      </div>
      
      
      
        {isLoggedIn ? (
          <div className='Personal-account-container'>
              <NavLink exact to="/account">
                <button className="Personal-account">Личный кабинет</button>
              </NavLink>
              <div className="headerPhoto">
                {userData.avatar_img === null ?(
                  <div className='header-photo-group'>
                    <img src={elipse} className="Elipse" alt="Elipse"/>
                    <img src={smile} className="Smile" alt="Smile"/>
                  </div>
                ) : (
                  <div className='header-photo-group'>
                    <img src={userData.avatar_img} className="doneElipse" />
                  </div>
                )}
              </div>
          </div>
          
          
        ) : (
          <div className="Login-but-container">
            <NavLink exact to="/authorization">
              <button className="Login-but">Войти</button>
            </NavLink>
          </div>
        )}
      
    </div>
  );
};

export default Header;