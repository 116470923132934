import React, { useEffect, useState } from "react";
import "../../../../styles/desktop/account/SendReports.css"

const RadioButton = (props) => {
    const {butValue, currentValue, setValue} = props
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if (currentValue === butValue) {
          setIsSelected(true);
        }
        else {
            setIsSelected(false)
        }
    }, [currentValue, setIsSelected]);

    return (
    <button className="radioBut" onClick={()=>setValue(butValue)}>
        {isSelected ? <span className="radioCircle"/>: null}
    </button>
    );
};

export default RadioButton;