import React, { useEffect, useState } from "react";
import "../../../../styles/desktop/account/SendReports.css"
import DropdownButton from "./Dropdown";
import RadioButton from "./RadioButton2";

const AddInfo = (props) => {
  const {setAddOptions} = props
  const [haveMessage, setHaveMessage] = useState(false)
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    setAddOptions({
      pk: selectedOption,
      subtype: haveMessage ? 1 : 0
    });
  }, [haveMessage, selectedOption]);

  return (
      <div className="addInfoCont">
        <span className="addInfoEl">
            <a>Количество подписчиков</a>
            <DropdownButton selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
        </span>
        <span className="addInfoEl" style={{marginLeft: "80px"}}>
            <a>Наличие сопроводительного сообщения</a>
            <RadioButton agree={haveMessage} setAgree={setHaveMessage}/>
        </span>
      </div>
  );
};

export default AddInfo;