import React, { useRef } from 'react';
import phone from '../../components/images/main page/Main_1_Form/MainPhone.jpg'
import ButtonMain1 from '../../components/comps/ButtonMain1';
import '../../styles/desktop/Main_page/Main_1_Form.css'

import Main_2_Form from './Main_2_Form';

const Main_1_Form = ({block2Ref} ) => {

    // const block2ref = useRef(null)

    // const scrolltoBlock = () => {
    //     block2ref.current.scrollIntoView({ behavior: 'smooth' });
    // }
    console.log({ block2Ref})

    return (
        <div className={'Main1'}>
            

            <img src={phone} className='MainPhone1' alt="Phone" />
            <div className={"maintext"}>
                ЗАРАБАТЫВАЙ <br /> НА РЕПОСТАХ <br /> И РЕКОМЕНДАЦИЯХ
            </div>
            <ButtonMain1 block2Ref={block2Ref}/>
        </div>
    );
};

export default Main_1_Form;