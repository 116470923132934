import React, { useEffect, useState } from "react";
import "../../../styles/mobile/account/SendReports.css"
import DropdownButton from "./Dropdown";
import RadioButton from "./RadioButton2";

const AddInfo = (props) => {
  const {setAddOptions} = props
  const [haveMessage, setHaveMessage] = useState(false)
  const [selectedOption, setSelectedOption] = useState();
  const [data, setData] = useState({})

  useEffect(() => {
    setAddOptions({
      pk: selectedOption,
      subtype: haveMessage ? 1 : 0
    });
  }, [haveMessage, selectedOption]);

  return (
      <div className="mobile_addInfoCont">
        <span className="mobile_addInfoEl">
            <a>Количество подписчиков</a>
            <DropdownButton selectedOption={selectedOption} setSelectedOption={setSelectedOption} data={data} setData={setData}/>
        </span>
        <a className='mobile_addInfoEl'>{data && !data.length == 0 ? "Выбрано: "+data[selectedOption - 1].name : ''}</a>
        <span className="mobile_addInfoEl">
            <a style={{marginTop: "10px"}}>Наличие сопроводительного сообщения</a>
            <RadioButton agree={haveMessage} setAgree={setHaveMessage}/>
        </span>
      </div>
  );
};

export default AddInfo;