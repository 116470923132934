import React, {useEffect, useRef} from 'react';
import Main_1_Form from '../services/Main_1_Form';
import Main_2_Form from '../services/Main_2_Form';
import Main_3_Form from '../services/Main_3_Form';
import Footer from '../services/Footer';
import Team_button from '../services/Team_button';

const Main = () => {

    const block2Ref = useRef(null);
    // useEffect(()=>{console.log(block2Ref)}, [block2Ref])

    return (
        <div>
            <Main_1_Form block2Ref={block2Ref}/>
            <Main_2_Form ref={block2Ref} />
            <Main_3_Form/>
            <Team_button/>
        </div>
    );
};

export default Main;