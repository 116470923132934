import React, { useEffect, useState } from "react"
import '../../../../styles/desktop/form styles/Form.css'
import InputComponent from "../../../../components/mobile/form comps/FormInput"
import Button from "../../../../components/mobile/form comps/FormButton"
import Error from "../../../../components/mobile/form comps/ErrorNotification";
import EyeComponent from "../../../../components/mobile/form comps/EyeComponent";
import Attention from "../../../../components/mobile/form comps/AttentionNotification";
import RadioButton from "../../../../components/mobile/form comps/RadioButton";
import axios from 'axios';

const MainRegistration = (props) => {
    const { number, setNumber, setIsRegistration } = props;
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [inputType, setInputType] = useState(false)
    const [error, setError] = useState('')
    const [isAttention, setIsAttention] = useState(false)
    const [agree, setAgree] = useState(false);

    const validation = () => {
        if (!number.includes('_') && number.length!==0){
                if (password.length>7){
                    if (password === confirmPassword){
                        agree ? handleRegistration() : setError("Вы не согласились на обработку данных.")
                    } else { 
                        if (confirmPassword!=="")setError("Введённые пароли не совпадают!")
                            else setError("Введите подтверждение пароля!")}
                }else {if ( password!=="") setError("Пароль должен быть не менее 8 символов!")
                    else setError("Введите пароль!")}
        } else setError("Введите корректный номер телефона!")
    }

    const handleRegistration = () => {
        const cleanedNumber = number.replace(/ /g, '');
        const data = ({
            phone_number: cleanedNumber,
            password: password,
            password2: confirmPassword,
        });
        axios.post('/api/auth/register/', data)
        .then(response => {
            // Обработка успешного ответа
            setIsRegistration(false)
            console.log(response.data);
        })
        .catch(error => {
            // Обработка ошибки
            try {
                let errorMessage;
                if (error.request.response.includes("phone_number")) {
                    errorMessage = JSON.parse(error.request.response).phone_number[0];
                } else {
                    errorMessage = "Ошибка регистрации!";
                }
                setError(errorMessage);
              } catch (error) {
                console.error('Ошибка при получении сообщения об ошибке', error);
            }
            console.error(error.request.response);
        });
    };

    return (
        <React.Fragment>
            {isAttention && <Attention/>}
            <div className="mobile-formContainer">
                <Error error={error} setError={setError} type="error"/>
                <span className="mobile-formTtle">РЕГИСТРАЦИЯ</span>
                <InputComponent inputValue = {number} action = {setNumber} mask="+7 999 999 99 99" placeholder="Телефон"  setIsAttention={setIsAttention}/>
                <InputComponent inputValue = {password} action = {setPassword} type = {inputType === true ? "text" : "password"}  placeholder="Пароль">
                    <EyeComponent inputType = {inputType} action = {() => setInputType(!inputType)}/></InputComponent>
                <InputComponent inputValue = {confirmPassword} action = {setConfirmPassword} type = {"password"} placeholder="Подтвердите пароль" 
                    password={password} confirmPassword={confirmPassword}/>
                <RadioButton agree={agree} setAgree={setAgree}/>
                <Button className="formButton" onClick={validation} sign="Зарегистрироваться"/>
            </div>
        </React.Fragment>
    );
};

export default MainRegistration;