import React, { useState, useEffect } from "react";
import '../../../../styles/desktop/form styles/Form.css';
import InputComponent from "../../../../components/comps/form comps/FormInput";
import EyeComponent from "../../../../components/comps/form comps/EyeComponent";
import Button from "../../../../components/comps/form comps/FormButton";
import Error from "../../../../components/comps/form comps/ErrorNotification";
import back from "../../../../components/images/form parts/signs/sign_back.svg";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const RecoveryPassword = (props) => {
    const { number, setIsGettingCode, setIsEnter, setIsRecovering, code} = props;
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inputType, setInputType] = useState(false)
    const [error, setError] = useState('');
    const [type, setType] = useState("message");
    const navigate = useNavigate();

    const validation = () => {
        if (password===confirmPassword && password.length>7){
            handleRecoveryPassword()
        } else if (password.length===0 || confirmPassword===0){
            setError('Введите данные!');
            setType("error");
        } else if (password.length<=7){
            setError('Пароль должен быть не менее 8 символов!');
            setType("error");
        } else if (password!==confirmPassword){
            setError('Пароли не совпадают!');
            setType("error");
        }
    }

    const handleRecoveryPassword = () => {
        const data = ({
            phone_number: number,
            otp: code,
            password: password,
            password2: confirmPassword
        });
  
        axios.post('/api/auth/recovery/reset_password/', data)
        .then(response => {
            // Обработка успешного ответа
            setIsEnter(true)
            setIsGettingCode(false)
            setIsRecovering(false)
            const token = response.data.token;
            localStorage.setItem('token', token);
            navigate('/account/templates');
            window.location.reload();
            console.log("Успешно сменен пароль.");
        })
        .catch(error => {
            // Обработка ошибки
            console.error(error.request.response);
        });
    };

    return (
    <div className="formContainer">
        <Error error={error} setError={setError} type={type}/>
        <img src={back} onClick={() => setIsGettingCode(false)} className="backSign" />
        <a href="#" onClick={() => setIsRecovering(false)} className="formLink" style={{right: "73px", marginTop: "10px"}}>Назад</a>
        <span className="formTtle" style={{marginTop: "30px"}}>ВОССТАНОВЛЕНИЕ ПАРОЛЯ</span>
        <InputComponent inputValue = {password} action = {setPassword} type = {inputType ? "text" : "password"} placeholder="Пароль">
            <EyeComponent inputType = {inputType} action = {() => setInputType(!inputType)}/></InputComponent>
        <InputComponent inputValue = {confirmPassword} action = {setConfirmPassword} type = {"password"} placeholder="Подтвердите пароль"
            password={password} confirmPassword={confirmPassword}/>
        <Button className="formButton" onClick={()=>validation()} sign="Отправить"/>
    </div>
    );
};

export default RecoveryPassword;