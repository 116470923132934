import React, { useState } from "react";
import RegistrationForm from "../services/authorization forms/registration/RegistrationForm";
import EnterForm from "../services/authorization forms/enter/EnterForm";
import "../../styles/mobile/form styles/Form.css";
import AuthBut from "../../components/mobile/form comps/AuthBut";
import { Navigate } from "react-router-dom";

function Athorization() {
  //true - регистрация
  //false - вход
  const [animState, setAnimState] = useState(true);
  const token = localStorage.getItem("token");
    
  if (token) {
      return <Navigate to="/account/templates" />;
  }

  return (
    <div className="mobile-horizontalContainer">
      <AuthBut sign={animState ?  "Войти" : "Регистрация" } action={()=>setAnimState(!animState)}/>
      <RegistrationForm animState={animState} setAnimState={setAnimState} />
      <EnterForm animState={animState} setAnimState={setAnimState} />
    </div>
  );
}

export default Athorization;