import React, { useState } from "react";
import contour from "../../images/mobile/account/profile/exit_cont.svg";
import inside from "../../images/account/profile parts/button_inside_hover2.svg";
import "../../../styles/desktop/account/UserProfile.css";

const ExitButton = (props) => {
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside : null;
  };

  return (
    <span className="exitButtonCont">
      {pic() && <img src={pic()} alt="Inside" className="exitInside" />}
      <img src={contour} alt="Contour" className="exitContour" />
      <button
        onClick={props.onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="exitButton"
      >
        Выйти из аккаунта
      </button>
    </span>
  );
};

export default ExitButton;