import React, { useRef, useState } from 'react';
import inside from "../../../components/images/mobile/Main_page/Main1Form.svg"
import inside_hover from "../../../components/images/mobile/Main_page/Main1FormHover.svg";


function ButtonMain1_mobile({block2Ref}) {
    
    const [hover, setHover] = useState(false);
    const [clicked, setClicked] =useState(false);
    const pic = () => {
      return( 
        clicked ? inside_hover :
        hover ? inside_hover : inside
      );
    };

    const scrolltoBlock = () => {
        block2Ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    
    return (
        <span className={'ButtonMain1_mobile_container'}>
            <img src={pic()} alt="Inside" className="ButtonMain1_mobile_img" />
            <button className='ButtonMain1_mobile' onClick={()=>{scrolltoBlock(); setClicked(false)}}> Узнать подробнее</button>
        </span>
    );
};

export default ButtonMain1_mobile;