import React from 'react';

const ListItem = ({ number, photo, date, status, comment, payment }) => {
    return (
        <div className="list-item">
            <div>{number}</div>
            <div>No Image</div>
            <div>{date}</div>
            <div>{status}</div>
            <div className='coment'>{comment}</div>
            <div>{payment}</div>
        </div>
    );
};

export default ListItem;