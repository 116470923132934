import React, { useState, useEffect } from "react";
import "../../../../styles/desktop/account/UserProfile.css"
import elipse from "../../../../components/images/account/profile parts/profile_elips.svg";
import smile from "../../../../components/images/account/profile parts/smile.svg";
import edit from "../../../../components/images/account/profile parts/edit.svg";
import link_contour from "../../../../components/images/account/profile parts/copyLink_contour.svg";
import CopyButton from "../../../../components/comps/account comps/CopyButton";
import ExitButton from "../../../../components/comps/account comps/ExitButton";
import MonthChoice from "../../../../components/comps/month choice/MonthChoice";
import balance_contour from "../../../../components/images/account/profile parts/balance_contour.svg";
import balance_inside from "../../../../components/images/account/profile parts/balance_inside.svg";
import rec_inside from "../../../../components/images/account/profile parts/recomentations_inside.svg";
import ruble from "../../../../components/images/account/profile parts/ruble.svg";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const UserProfile = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [link, setLink] = useState('')
  const [month, setMonth] = useState('1 месяц')

  const [userData, setUserData] = useState({});

  const dataHandle = () => {
    const config = {
      headers: {
        Authorization: "Token "+token
      }
    };
    axios.get('/api/account/get_account_snippet/', config)
      .then(response => {
        // Обработка успешного ответа
        const userData = response.data;
        setUserData(userData);
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  };

  useEffect(() => {
    dataHandle()
    linkHandle()
  }, []);

  const exitHandle =()=> {
    const config = {
      method: 'post',
      headers: {
          Authorization: "Token " + token
      }
    };
    axios('/api/auth/logout/', config)
      .then(response => {
        // Обработка успешного ответа
        localStorage.removeItem("token");
        navigate('/')
        window.location.reload();
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  }

  const linkHandle =()=> {
    const config = {
      headers: {
          Authorization: "Token " + token
      }
    };
    axios.get('/api/account/get_main_bounty_link/', config)
      .then(response => {
        // Обработка успешного ответа
        const link = response.data.data.link;
        setLink(link)
      })
      .catch(error => {
        // Обработка ошибки
        console.error(error.request.response);
      });
  }

  return (
    <div className="mainUserInfoContainer">
      <span class="editSign"><img src={edit} alt="Edit" 
        onClick={() => {
          navigate('/account/editing');
          window.location.reload(); // нужно чтобы фотобатон работал сразу
        }}/>
      </span>
      <div className="infoContainer">
        <span className="infoPhotoCont" onClick={()=>navigate('/account/editing')}>
          {userData.avatar_img === null ?(
            <span>
              <img src={elipse} className="infoElipse" alt="Elipse"/>
              <img src={smile} className="infoSmile" alt="Smile"/>
            </span>
          ) : (
            <img src={userData.avatar_img} className="infoElipse" />
          )}
        </span>
        <a className="infoTitle" onClick={()=>navigate('/account/editing')}>{userData.username ? userData.username : "Добавьте ник"}</a>
        <a className="infoText">{userData.phone_number}</a>
      </div>
      <div className="copyLinkContainer">
        <a className="copyLinkTitle">Копировать реферальную ссылку<br/>на основной сайт компании MezEx</a>
        <img src={link_contour} className="linkContour" />
        <CopyButton link={link}/>
      </div>
      <div className="squaresCont">
        <span className="balanceContainer">
          <img src={balance_inside} alt="Balance I" className="balanceInside" />
          <img src={balance_contour} alt="Balance C" className="balanceContour" />
          <a className="balanceTitle">Баланс:</a>
          <span className="balanceNumbsContainer">
            <a className="balanceNumbs">{userData.balance}</a>
            <img src={ruble} alt="Ruble" className="ruble" />
          </span>
        </span>
        <span className="balanceContainer">
          <img src={rec_inside} alt="Rec I" className="balanceInside" />
          <img src={balance_contour} alt="Rec C" className="balanceContour" />
          <a className="balanceTitle">Рекомендаций:</a>
          <span className="balanceNumbsContainer">
            <a className="balanceNumbs">{userData.recommendations}</a>
          </span>
        </span>
      </div>
      <div className="statisticContainer">
        <span className="statisticLine">
            <MonthChoice month={month} setMonth={setMonth} style={1}/>
        </span>
        {month=='1 месяц' ? 
        (<>
          <span className="statisticLine">
              <a className="statisticText">Пользователей перешло по ссылке:</a>
              <a className="statisticNumbs">{userData.leads}</a>
          </span>
          <span className="statisticLine">
              <a className="statisticText">Пользователей обратилось в компанию:</a>
              <a className="statisticNumbs">{userData.target_leads}</a>
          </span>
          <span className="statisticLine">
              <a className="statisticText">Пользователей заключило договор:</a>
              <a className="statisticNumbs">{userData.contracts}</a>
          </span>
          <span className="statisticLine">
              <a className="statisticText">Сделок состоялось:</a>
              <a className="statisticNumbs">{userData.deals}</a>
          </span>
        </>) : (
          <>
          <span className="statisticLine">
              <a className="statisticText">Пользователей перешло по ссылке:</a>
              <a className="statisticNumbs">{userData.three_month_leads}</a>
          </span>
          <span className="statisticLine">
              <a className="statisticText">Пользователей обратилось в компанию:</a>
              <a className="statisticNumbs">{userData.three_month_target_leads}</a>
          </span>
          <span className="statisticLine">
              <a className="statisticText">Пользователей заключило договор:</a>
              <a className="statisticNumbs">{userData.three_month_contracts}</a>
          </span>
          <span className="statisticLine">
              <a className="statisticText">Сделок состоялось:</a>
              <a className="statisticNumbs">{userData.three_month_deals}</a>
          </span>
        </>
        )
        }
          <span className="statisticLine">
              <a className="statisticText">Ранг:</a>
              <a className="statisticNumbs">{userData.rank_name}</a>
          </span>
      </div>
      <ExitButton onClick={()=>exitHandle()}/>
    </div>
  );
};

export default UserProfile;