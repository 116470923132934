import React from 'react';
import '../../../styles/desktop/account/LeftSideBar.css'
import { NavLink } from 'react-router-dom';


const LeftSideBar = () => {
    return (
        <div className='LeftSideBar'>
             <NavLink exact="true" to="/account/templates">
                <button className='templates'>Шаблоны</button>
             </NavLink>
             <NavLink exact="true" to="/account/reports">
                <button className="reports">Отчеты</button>
             </NavLink>

             <NavLink exact="true" to="/account">
                <button className="wallet">Мой кошелек</button>
             </NavLink>
            
        </div>
    );
};

export default LeftSideBar;