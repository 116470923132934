import React, {useState, useEffect} from 'react';
import axios from 'axios';

import '../../../styles/mobile/MainPage/Main_3_Form_Mobile.css'

import Left_man from '../../../components/images/main page/Main_3_Form/Left_man.svg'
import Left_table_contur from '../../../components/images/mobile/Main_page/Left-table-contur-mobile.svg'

import Right_table_contur from '../../../components/images/mobile/Main_page/Right-side-border-mobile.svg'

const Main_3_Form_Mobile = () => {
    const [userData, setUserData] = useState([
        {
          "name": "M",
          "bonus_rate": 3,
          "description": "Регистрация в программе"
        },
        {
          "name": "МЕ",
          "bonus_rate": 4,
          "description": "Выполнение плана по размещению ссылок"
        },
        {
          "name": "MEZ",
          "bonus_rate": 5,
          "description": "От 30 целевых заявок"
        },
        {
          "name": "MEZE",
          "bonus_rate": 7,
          "description": "От 60 целевых заявок"
        },
        {
          "name": "Амбассадор MEZEX",
          "bonus_rate": 10,
          "description": "От 100 целевых заявок"
        }
      ]
    );



    const getData = () => {
        const token = localStorage.getItem("token");
    
        const config = {
          headers: {
            Authorization: "Token "+token
          }
        };
        console.log(config)
      
        axios
          .get('/api/main/get_rank_list/', null)
          .then(response => {
            // Обработка успешного ответа
            const userData = response.data.data;
            setUserData(userData);
            console.log("Успешно получены данные.")
            console.log(userData)
          })
          .catch(error => {
            // Обработка ошибки
            console.error(error.request.response);
          });
    };

      useEffect(() => {
        getData(); // Запускаем функцию getData при загрузке страницы
      }, []);


      const [userData1, setUserData1] = useState([
        {
          "name": "Персональное сообщение",
          "reward_1": 5,
          "reward_2": 5
        },
        {
          "name": "От 20 до 50",
          "reward_1": 20,
          "reward_2": 30
        },
        {
          "name": "От 51 до 150",
          "reward_1": 40,
          "reward_2": 50
        },
        {
          "name": "От 151 до 500",
          "reward_1": 60,
          "reward_2": 70
        },
        {
          "name": "Более 501",
          "reward_1": 80,
          "reward_2": 90
        }
      ]
    );

    

    const getData1 = () => {
        const token = localStorage.getItem("token");
    
        const config = {
          headers1: {
            Authorization: "Token "+token
          }
        };
        console.log(config)
      
        axios
          .get('/api/main/get_action_list/', null)
          .then(response => {
            // Обработка успешного ответа
            const userData1 = response.data.data;
            setUserData1(userData1);
            console.log("Успешно получены данные.")
            console.log(userData1)
          })
          .catch(error => {
            // Обработка ошибки
            console.error(error.request.response);
          });
    };

      useEffect(() => {
        getData1(); // Запускаем функцию getData при загрузке страницы
      }, []);


    
    return (
        <div className={'Main3-mobile'}>
                <div className={"left-side-mobile"}>
                    <div className="speak-window-mobile">
                        <p className='speak-window-p-mobile'>Чем больше ранг, <br /> тем выше ваш процент <br /> в случае сделки</p>
                    </div>
                    <img className={'Left_man-mobile'} src={Left_man} alt="" />
                    <div className="table-group-mobile">

                        
                        {/* <img className={'Left_table_line'} src={Left_table_line} alt="" /> */}
                        <img className={'Left_table-contur-mobile'} src={Left_table_contur} alt="" />

                        <p className='table-header-mobile'>НАЧИСЛЕНИЕ ПО РАНГАМ</p>
                        <table border="0"> 
                            <tr> 
                                <th>Ранг</th> 
                                <th>Бонус</th>
                                
                            </tr>
                            <tr>
                            
                                <td>{userData[0].name}</td>
                                <td>{userData[0].bonus_rate}%</td>

                                
                            </tr>
                            <tr>
                                <td>{userData[1].name}</td>
                                <td>{userData[1].bonus_rate}%</td>
                                
                            </tr>
                            <tr>
                                <td>{userData[2].name}</td>
                                <td>{userData[2].bonus_rate}%</td>
                                
                            </tr>
                            <tr>
                                <td>{userData[3].name}</td>
                                <td>{userData[3].bonus_rate}%</td>
                                
                            </tr>
                            <tr>
                                <td>{userData[4].name}</td>
                                <td>{userData[4].bonus_rate}%</td>
                                
                            </tr>
                        </table>
                    </div>
                </div>

                <div className={"right-side-mobile"}>
                    
                        <div className="table-wrapper-mobile">
                            <p style={{ textAlign: 'left' }}>НАЧИСЛЕНИЯ ПО РЕКОМЕНДАЦИЯМ</p>
                            <table>
                                <tr>
                                    <th>Количество подписчиков</th>
                                    <th>Пост без сопровождения</th>
                                    <th>Пост с сопроводительным позитивным письмом</th>
                                </tr>
                                <tr>
                                    <td>{userData1[0].name}</td>
                                    <td>{userData1[0].reward_1}</td>
                                    <td>{userData1[0].reward_2}</td>
                                </tr>
                                <tr>
                                    <td>{userData1[1].name}</td>
                                    <td>{userData1[1].reward_1}</td>
                                    <td>{userData1[1].reward_2}</td>
                                </tr>
                                <tr>
                                    <td>{userData1[2].name}</td>
                                    <td>{userData1[2].reward_1}</td>
                                    <td>{userData1[2].reward_2}</td>
                                </tr>
                                <tr>
                                    <td>{userData1[3].name}</td>
                                    <td>{userData1[3].reward_1}</td>
                                    <td>{userData1[3].reward_2}</td>
                                </tr>
                                <tr>
                                    <td>{userData1[4].name}</td>
                                    <td>{userData1[4].reward_1}</td>
                                    <td>{userData1[4].reward_2}</td>
                                </tr>
                                <tr>
                                    <td>Сделка с клиентом по реферальной ссылке</td>
                                    <td colSpan={"2"}>3-10% от комисии (2-5 тысячи рублей)</td>
                                    
                                </tr>
                            </table>
                            <img className={'Right_table-contur-mobile'} src={Right_table_contur} alt="" />
                        </div>                        
                    </div>
                   

        </div>
    );
};

export default Main_3_Form_Mobile;