import React, { useState } from "react";
import classes from "../../../../styles/desktop/account/EditingMain.module.css"
import contour from "../../../images/account/editing parts/confirmButton_contour.svg"
import inside from "../../../images/account/editing parts/confirmButton_inside.svg"
import inside_hover from "../../../images/account/editing parts/confirmButton_hover.svg"

const ConfirmButton = (props) => {
  const [hover, setHover] = useState(false);
  const pic = () => {
    return hover ? inside_hover : inside;
  };

  return (
    <span className={classes.confirmButtonCont}>
      <img src={pic()} alt="Inside" className={classes.confirmInside} style={props.style}/>
      <img src={contour} alt="Contour" className={classes.confirmContour} style={props.style}/>
      <button onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.confirmButton}
        style={props.style}>
        Подтвердить
      </button>
    </span>
  );
};

export default ConfirmButton;